import {Icon} from '@trussworks/react-uswds';
import {ReactNode} from 'react';
import {Tooltip} from 'react-tooltip';

interface Props {
  id: string;
  className?: string;
  children: ReactNode;
}

export default function QuestionTooltip({id, className, children}: Props) {
  return (
    <>
      {/* Mobile */}
      <Icon.Help
        id={id}
        size={3}
        color="gray"
        className={`tablet:display-none ${className ? className : ''}`}
        aria-label="Tip"
        role="button"
      />
      <Tooltip anchorSelect={`#${id}`} place="bottom" className="z-top" openOnClick>
        {children}
      </Tooltip>
      {/* Non-Mobile */}
      <Icon.Help
        id={id}
        size={3}
        color="gray"
        className={`display-none tablet:display-inline-block ${className ? className : ''}`}
        aria-label="Tip"
        role="button"
      />
      <Tooltip anchorSelect={`#${id}`} place="bottom" className="z-top">
        {children}
      </Tooltip>
    </>
  );
}

import axios from 'axios';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {ErrorResBody} from '../../common/types/error-response';
import SuccessFalseError from '../lib/success-false-error';
import {MtgBroadcastPostResBody} from '../../common/types/mtg-broadcast-post-res';
import {MtgBroadcastPostReqBody} from '../../common/types/mtg-broadcast-post-req';
import {SendBroadcastForm} from '../types/broadcast-types';
import {isEmailValid, splitAndTrimEmails} from '../lib/utils';
import metadata_client_broadcast from '../lib/metadata-client-broadcast.json';

async function sendBroadcast(mtg_id: string, req_body: MtgBroadcastPostReqBody) {
  const res = await axios.post<MtgBroadcastPostResBody | ErrorResBody>(
    `/api/meetings/${mtg_id}/broadcast`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export interface MtgBroadcastMutationParams {
  mtg_id: string;
  form: SendBroadcastForm;
  is_preview: MtgBroadcastPostReqBody['is_preview'];
}

export function useMutationSendBroadcast() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({mtg_id, form, is_preview}: MtgBroadcastMutationParams) => {
      const addtl_emails = splitAndTrimEmails(form.addtl_emails);
      if (addtl_emails.length && !addtl_emails.every(isEmailValid))
        throw new Error(`An email in ${metadata_client_broadcast.addtl_emails.title} is invalid`);

      const formatted_body: MtgBroadcastPostReqBody = {
        ...form,
        is_preview,
        addtl_emails,
        reply_to_email: form.reply_to_email.trim() || null,
      };
      return sendBroadcast(mtg_id, formatted_body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['emails'], type: 'all'});
    },
  });
}

// Spinner from CMSgov/design-system
// License: "worldwide public domain" - https://github.com/CMSgov/design-system/blob/main/LICENSE.md
//
// We don't expect to use many (or possibly any) other components from CMSgov/design-system, so this
// component has been extracted as a standalone component, introducing minimal overhead to the final
// compiled js/css.
//
// .tsx component revision: https://github.com/CMSgov/design-system/blob/6dc8ba72ef5057f435eeda60d6573d21bf12892c/packages/design-system/src/components/Spinner/Spinner.tsx

import {isTruthy} from '../../../lib/utils';
import styles from './Spinner.module.scss';

export type SpinnerSize = 'small' | 'big' | 'file_upload';

export interface SpinnerProps {
  /**
   * The text announced to screen readers
   */
  'aria-valuetext'?: string;
  /**
   * Additional classes to be added to the spinner element.
   * Useful for adding utility classes.
   */
  'className'?: string;
  /**
   * Applies the inverse theme styling
   */
  'inversed'?: boolean;
  /**
   * Adds a background behind the spinner for extra contrast
   */
  'filled'?: boolean;
  /**
   * Landmark role so the spinner can receive keyboard focus
   */
  'role'?: string;
  /**
   * Smaller or larger variant
   */
  'size'?: SpinnerSize;
}

/**
 * "Pending operation" spinner
 * @param props Component options
 *
 * Reference https://design.cms.gov/components/spinner/?theme=core#react for more details on
 * component options.
 */
export const Spinner: React.FunctionComponent<SpinnerProps> = ({
  'aria-valuetext': ariaValuetext,
  className,
  inversed,
  filled,
  role = 'status',
  size,
}: SpinnerProps) => {
  const all_classes = [
    'spinner',
    styles['ds-c-spinner'],
    size && styles[`ds-c-spinner--${size}`],
    inversed && styles['ds-c-spinner--inverse'],
    filled && styles['ds-c-spinner--filled'],
    className,
  ]
    .filter(isTruthy)
    .join(' ');

  return (
    <span className={all_classes} role={role}>
      <span className={styles['ds-u-visibility--screen-reader']}>{ariaValuetext ?? 'Loading'}</span>
    </span>
  );
};

export default Spinner;

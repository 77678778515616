import {useEffect, useState} from 'react';
import {
  addOrRemoveValidationMessage,
  isEmailValid,
  splitAndTrimEmails,
} from '../../../../lib/utils';
import {FormType, SemiUncontrolled, SetValidationMessages} from '../../../../types/form-types';
import FormTextArea, {HandleTextAreaChange} from '../../FormTextArea/FormTextArea';
import EmailPills from './EmailPills/EmailPills';
import metadata_client_meeting from '../../../../lib/metadata-client-meeting.json';

type Props = {
  id_name: string;
  input: string;
  handleChange: HandleTextAreaChange;
  setValidationMessages: SetValidationMessages;
  form_type: FormType;
  custom_jsx?: React.ReactNode;
} & SemiUncontrolled;

export default function EmailsInput({
  id_name,
  input,
  handleChange,
  setValidationMessages,
  form_type,
  custom_jsx,
  use_semi_uncontrolled,
  debounce_change_ms,
}: Props) {
  const [isValid, setIsValid] = useState(true);
  const trimmed_emails = splitAndTrimEmails(input);

  useEffect(() => {
    const is_valid = !trimmed_emails.length || trimmed_emails.every(isEmailValid);

    addOrRemoveValidationMessage({
      is_condition_met: !is_valid,
      err_message: metadata_client_meeting.cc_emails.validation_message,
      setValidationMessages,
    });

    setIsValid(is_valid);
  }, [input, setValidationMessages, trimmed_emails]);

  const control_props = use_semi_uncontrolled ? {use_semi_uncontrolled, debounce_change_ms} : {};

  return (
    <FormTextArea
      id_name={id_name}
      input={input}
      handleChange={handleChange}
      setValidationMessages={setValidationMessages}
      custom_jsx={
        <>
          {custom_jsx}
          {!!input && <EmailPills emails={trimmed_emails} validate_emails />}
        </>
      }
      is_invalid={!isValid}
      include_desc
      include_hint
      form_type={form_type}
      {...control_props}
    />
  );
}

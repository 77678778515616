import {useState, useMemo} from 'react';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Breadcrumb,
  BreadcrumbBar,
  Button,
  FormGroup,
  Label,
  TextInput,
} from '@trussworks/react-uswds';
import {Link} from 'react-router-dom';
import metadata_client_session_log from '../../lib/metadata-client-session-log.json';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import TableUI from '../../components/TableUI/TableUI';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {fromUTCtoET, scrollToTop, session_revoke_success} from '../../lib/utils';
import {useQuerySessionLog} from '../../services/session-mgmt-queries';
import {SessionLogGetItem} from '../../../common/types/session-log-get-res';
import {Duration} from 'luxon';
import LongFieldWithTooltip from '../../components/LongFieldWithTooltip/LongFieldWithTooltip';
import DeleteButton from '../../components/SessionLogComponents/DeleteButton/DeleteButton';
import DeleteModal, {
  DeleteModalState,
} from '../../components/SessionLogComponents/DeleteModal/DeleteModal';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

const initial_delete_modal_state: DeleteModalState = {
  is_open: false,
  email: '',
  session_pk: '',
};

export default function SessionLog() {
  usePageTitle('Active session log');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>(
    initial_delete_modal_state
  );
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const query = useQuerySessionLog();
  const privileges = usePrivilegesContext();

  const last_refresh = useMemo(
    () =>
      query.isPending
        ? 'Loading...'
        : fromUTCtoET(new Date(query.dataUpdatedAt).toISOString(), {use_long_date: true}),
    [query.dataUpdatedAt, query.isPending]
  );

  function handleDeleteSuccess() {
    setShowDeleteSuccess(true);
    scrollToTop();
  }

  function handleModalDismiss() {
    setDeleteModalState(initial_delete_modal_state);
  }

  function handleDeleteClick(email: string, session_pk: string) {
    setShowDeleteSuccess(false);
    setDeleteModalState({is_open: true, email, session_pk});
  }

  function handleRefresh() {
    setShowDeleteSuccess(false);
    void query.refetch();
  }

  const columns = useMemo<ColumnDef<SessionLogGetItem>[]>(
    () => [
      {
        accessorKey: 'email',
        header: metadata_client_session_log.email.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'session_pk',
        cell: (info) => {
          const {email, session_pk, is_current_session} = info.row.original;
          return (
            <DeleteButton
              handleClick={() => handleDeleteClick(email, session_pk)}
              session_pk={session_pk}
              is_current_session={is_current_session}
            />
          );
        },
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'start_time',
        cell: (info) => fromUTCtoET(info.getValue<string>(), {use_long_date: true}),
        header: metadata_client_session_log.start_time.title,
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.active_sec ?? Number.MAX_SAFE_INTEGER,
        cell: (info) => {
          const {active_sec} = info.row.original;
          return active_sec != null
            ? Duration.fromMillis(active_sec * 1000).toFormat("d'd' hh'h' mm'm' ss's'")
            : 'Inactive';
        },
        header: metadata_client_session_log.active_time.title,
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'expiration',
        cell: (info) => fromUTCtoET(info.getValue<string>(), {use_long_date: true}),
        header: metadata_client_session_log.expiration.title,
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.user_agent ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_session_log.user_agent.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'user_fk',
        cell: (info) => {
          const my_pk = privileges.pk;
          const user_pk = info.getValue<string>();
          return (
            <Link
              to={user_pk == my_pk ? '/my-account' : `/manage-accounts/account/${user_pk}`}
              className="usa-link text-no-wrap"
              data-user_pk={user_pk == my_pk ? user_pk : undefined}
            >
              {user_pk == my_pk ? 'My account' : 'View account'}
            </Link>
          );
        },
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    [privileges.pk]
  );

  // The API response includes expired sessions that have not yet been deleted (cleanup happens every 15 minutes). Their
  // inclusion in the log leads to some confusion. We filter them out on the frontend, retaining the ability to revisit
  // expired session handling in the future.
  const active_sessions = useMemo(
    () => (query.data ?? []).filter((item) => !item.is_expired),
    [query.data]
  );

  const table = useReactTable({
    data: active_sessions,
    columns,
    state: {sorting, globalFilter},
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnPinning: {
        right: ['user_fk'],
      },
    },
  });

  // This shouldn't render since we're using err boundary in the query
  if (query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Active session log</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {showDeleteSuccess && <SuccessAlert>{session_revoke_success}</SuccessAlert>}
      <section className="grid-row grid-gap word-break-word margin-bottom-2">
        <div className="mobile-lg:grid-col margin-top-2 display-flex flex-column">
          <div className="display-inline-block margin-y-auto">
            Results as of: <span className="text-bold">{last_refresh}</span>
          </div>
        </div>
        <div className="mobile-lg:grid-col-auto margin-top-2 text-right">
          <Button
            type="button"
            className="usa-button--outline text-middle margin-right-0"
            onClick={handleRefresh}
            disabled={query.isFetching}
          >
            Refresh
          </Button>
        </div>
      </section>
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word">
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            Search emails
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
      </section>
      {query.isPending ? (
        <PageLoading message="Loading sessions" />
      ) : (
        <>
          <TableUI
            table={table}
            clickedIndex={clickedIndex}
            setClickedIndex={setClickedIndex}
            variant="session_log_table"
          />
          <DeleteModal
            handleDismiss={handleModalDismiss}
            handleSuccess={handleDeleteSuccess}
            {...deleteModalState}
          />
        </>
      )}
    </>
  );
}

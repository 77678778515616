import {Button} from '@trussworks/react-uswds';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import SectionTitle from '../../components/FormComponents/SectionTitle/SectionTitle';
import {useEffect, useRef, useState} from 'react';
import EmailsInput from '../../components/FormComponents/MeetingFormComponents/EmailsInput/EmailsInput';
import {HandleInputChange, HandleSubmit, ValidationMessages} from '../../types/form-types';
import metadata_client_broadcast from '../../lib/metadata-client-broadcast.json';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import {support_email} from '../../../common/lib/shared-vars';
import {handleTypedCheckbox} from '../../components/FormComponents/FormCheckbox/FormCheckbox';
import {
  ActiveTab,
  addOrRemoveValidationMessage,
  broadcast_success,
  handleTypedInputChange,
  scrollToTop,
} from '../../lib/utils';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import FormEmailInput from '../../components/FormComponents/FormEmailInput/FormEmailInput';
import CheckboxWithTooltip from '../../components/FormComponents/CheckboxWithTooltip/CheckboxWithTooltip';
import {MeetingsGetByIdData} from '../../../common/types/meetings-get-by-id-res';
import InlineAlert from '../../components/FormComponents/InlineAlert/InlineAlert';
import {useQueryMyAccount} from '../../services/account-queries';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {SendBroadcastForm} from '../../types/broadcast-types';
import {useMutationSendBroadcast} from '../../services/broadcast-queries';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import SendMtgBroadcastModal from '../../components/MeetingComponents/SendMtgBroadcastModal/SendMtgBroadcastModal';
// eslint-disable-next-line max-len
import MtgBroadcastSuccessAccordion from '../../components/MeetingComponents/MtgBroadcastSuccessAccordion/MtgBroadcastSuccessAccordion';
import {useQueryClientConfig} from '../../services/config-queries';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';

export interface BroadcastProps {
  meeting_id: string;
  meeting_data: MeetingsGetByIdData;
  active_tab: ActiveTab;
}

export type MtgBroadcastModalState = {
  is_open: boolean;
} & (
  | {
      is_preview: true;
      preview_email: string;
    }
  | {
      is_preview?: false;
      preview_email?: never;
    }
);

export default function MtgBroadcast({meeting_id, active_tab, meeting_data}: BroadcastProps) {
  const my_account_query = useQueryMyAccount('default', {is_enabled: active_tab == 'broadcast'});
  const client_config_query = useQueryClientConfig({is_enabled: active_tab == 'broadcast'});
  const meeting_url = `${location.origin}/meetings/${meeting_id}`;
  const initial_state = {
    include_email_in_person: false,
    include_email_virtual: false,
    include_email_self: false,
    addtl_emails: '',
    reply_to_email: '',
    email_subject: '',
    email_body_text:
      `This email is being sent in regards to the PHMSA meeting, "${meeting_data.title}". You may review details ` +
      'about this meeting, as well as review your registration status for this meeting at the following link: ' +
      `${meeting_url}.`,
  };
  const [modalState, setModalState] = useState<MtgBroadcastModalState>({is_open: false});
  const [formData, setFormData] = useState<SendBroadcastForm>({...initial_state});
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const mutation = useMutationSendBroadcast();

  const [initialForm, setInitialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  const handleEmailChange: HandleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData((formData) => ({...formData, [name]: value.trim()}));
  };

  const handleCheckbox = handleTypedCheckbox(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);
  const handleInputChange = handleTypedInputChange(setFormData);

  function handlePreview() {
    mutation.mutate(
      {mtg_id: meeting_id, form: formData, is_preview: true},
      {
        onSuccess: (data) => {
          setModalState({
            is_open: true,
            is_preview: true,
            preview_email: data.aggregate_emails[0] ?? '',
          });
        },
      }
    );
  }

  // Leave preview and submit buttons disabled while confirmation modal is visible. No validation items affect our
  // ability to send previews.
  const is_submit_disabled =
    !!validationMessages.length || mutation.isPending || modalState.is_open;
  const is_preview_disabled = mutation.isPending || modalState.is_open;
  const is_discard_disabled = mutation.isPending;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    setModalState({is_open: true, is_preview: false});
  };

  function sendBroadcast() {
    mutation.mutate(
      {mtg_id: meeting_id, form: formData, is_preview: false},
      {
        onSuccess: () => {
          setModalState({is_open: false});
          setFormData(() => {
            const resetFormData = {
              ...initial_state,
              reply_to_email: my_account_query.data?.email ?? '',
            };
            setInitialForm(resetFormData);
            return resetFormData;
          });
          scrollToTop();
        },
      }
    );
  }

  useEffect(() => {
    // If any validation items are added that affect our ability to send previews, the logic for is_preview_disabled
    // above should be revisited.
    const is_email_list_empty =
      (!formData.include_email_in_person || !meeting_data.in_person_registrant_count) &&
      (!formData.include_email_virtual || !meeting_data.virtual_registrant_count) &&
      !formData.addtl_emails.trim();
    addOrRemoveValidationMessage({
      is_condition_met: is_email_list_empty,
      err_message:
        'Please select at least one recipient format or enter emails in Additional Emails',
      setValidationMessages,
    });
  }, [
    formData.addtl_emails,
    formData.include_email_in_person,
    formData.include_email_virtual,
    meeting_data.in_person_registrant_count,
    meeting_data.virtual_registrant_count,
  ]);

  // Update form with email from my account query as soon as it's ready. Set updated form data to initialForm as well so
  // that discard detection does not flag the form as dirty due to insertion of email. Note that user interaction while
  // waiting to set an updated form state is not a concern because the page loader displays until the query completes.
  useEffect(() => {
    setFormData((prevState) => {
      const newFormData = {
        ...prevState,
        reply_to_email: prevState.reply_to_email || (my_account_query.data?.email ?? ''),
      };
      setInitialForm(newFormData);
      return newFormData;
    });
  }, [my_account_query.data?.email]);

  if (client_config_query.isError) throw new Error('Client config query error');

  return (
    <>
      {my_account_query.isPending || client_config_query.isPending ? (
        <PageLoading message="Loading broadcast" />
      ) : (
        <section>
          {/* This alert should only be shown for a successful broadcast */}
          {mutation.isSuccess && !mutation.variables.is_preview && (
            <SuccessAlert>
              <span>
                {broadcast_success} The replies will be sent to{' '}
                <i>{mutation.data.reply_to_email}</i>.
              </span>
              <MtgBroadcastSuccessAccordion emails={mutation.data.aggregate_emails} />
            </SuccessAlert>
          )}
          {/* This err is only for the preview. If an err occurs during broadcast, it will be shown in the modal */}
          {mutation.isError && mutation.variables.is_preview && (
            <FormGenericError error={mutation.error} />
          )}
          {
            <p>
              This email will appear to be from{' '}
              <i>{client_config_query.data.default_sender_email_name}</i> at{' '}
              <i>{client_config_query.data.default_sender_email}.</i>
            </p>
          }
          <FormLayout handleSubmit={handleSubmit}>
            <SectionTitle>Recipients</SectionTitle>
            <div className="grid-row">
              <CheckboxWithTooltip
                id_name="include_email_in_person"
                is_checked={formData.include_email_in_person}
                label={metadata_client_broadcast.include_email_in_person.title}
                tooltip_text={metadata_client_broadcast.include_email_in_person.hint}
                handleCheckbox={handleCheckbox}
                is_disabled={!meeting_data.in_person_registrant_count}
                disabled_tooltip={metadata_client_broadcast.include_email_in_person.disabled_hint}
              />
              <CheckboxWithTooltip
                id_name="include_email_virtual"
                is_checked={formData.include_email_virtual}
                label={metadata_client_broadcast.include_email_virtual.title}
                tooltip_text={metadata_client_broadcast.include_email_virtual.hint}
                handleCheckbox={handleCheckbox}
                is_disabled={!meeting_data.virtual_registrant_count}
                disabled_tooltip={metadata_client_broadcast.include_email_virtual.disabled_hint}
              />
            </div>
            <EmailsInput
              id_name="addtl_emails"
              input={formData.addtl_emails}
              handleChange={handleTextAreaChange}
              setValidationMessages={setValidationMessages}
              form_type="broadcast"
              custom_jsx={
                <>
                  {!formData.include_email_in_person &&
                    !formData.include_email_virtual &&
                    formData.addtl_emails && (
                      <InlineAlert>
                        If no virtual or in-person recipients are selected, the email broadcast will
                        only be sent to the listed emails
                      </InlineAlert>
                    )}
                </>
              }
            />
            <section className="margin-top-3">
              <SectionTitle>{metadata_client_broadcast.include_email_self.title}</SectionTitle>
              <div className="grid-row">
                <CheckboxWithTooltip
                  id_name="include_email_self"
                  is_checked={formData.include_email_self}
                  label={metadata_client_broadcast.include_email_self.title}
                  tooltip_text={metadata_client_broadcast.include_email_self.hint}
                  handleCheckbox={handleCheckbox}
                />
              </div>
            </section>
            <FormEmailInput
              id_name="reply_to_email"
              input={formData.reply_to_email}
              handleChange={handleEmailChange}
              setValidationMessages={setValidationMessages}
              // eslint-disable-next-line max-len
              custom_desc={`Optional email address that will receive replies. If left blank, replies will be sent to ${support_email}.`}
              form_type="broadcast"
            />
            <FormTextInput
              id_name="email_subject"
              input={formData.email_subject}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              is_required
              include_desc
              form_type="broadcast"
            />
            <FormTextArea
              id_name="email_body_text"
              input={formData.email_body_text}
              handleChange={handleTextAreaChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              is_required
              include_desc
              form_type="broadcast"
            />
            <BottomContainer>
              <div className="grid-row flex-justify-end">
                <div className="grid-row margin-right-neg-2 margin-top-neg-105">
                  <div className="margin-top-105 margin-right-2 flex-grow">
                    <Button
                      type="button"
                      className="margin-top-0-important margin-0"
                      onClick={handlePreview}
                      disabled={is_preview_disabled}
                      outline
                    >
                      Preview (send to self)
                    </Button>
                  </div>
                  <div className="margin-top-105 margin-right-2 flex-grow">
                    <FormSubmit
                      validationMessages={validationMessages}
                      is_disabled={is_submit_disabled}
                      ref={submit_btn_ref}
                    >
                      Send broadcast
                    </FormSubmit>
                  </div>
                </div>
              </div>
            </BottomContainer>
            <DiscardChanges
              isFormDirty={isFormDirty}
              disable_nav_blocker={is_discard_disabled}
              visibility="none"
              watch_url_segments={{pathname: true, search: true, hash: true}}
            />
          </FormLayout>
          <SendMtgBroadcastModal
            modalState={modalState}
            setModalState={setModalState}
            sendBroadcast={sendBroadcast}
            mutation={mutation}
          />
        </section>
      )}
    </>
  );
}

import {AccountGetPutData, AccountGetPutPiv} from '../../../../common/types/account-get-put-res';
import {
  account_view_additional_fields,
  meeting_view_additional_fields,
  piv_view_additional_fields,
  registrant_view_additional_fields,
} from '../../../lib/utils';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import metadata_client_meeting from '../../../lib/metadata-client-meeting.json';
import metadata_client_piv from '../../../lib/metadata-client-piv.json';
import {Fragment, useState} from 'react';
import {Button, Tag} from '@trussworks/react-uswds';
import {MeetingsGetByIdData} from '../../../../common/types/meetings-get-by-id-res';
import {RegistrantGetResData} from '../../../../common/types/registrant-get-res';
import {RegistrantsGetByUserIdData} from '../../../../common/types/registrants-get-by-user-id-res';
import {SetIsPivDeleteSuccess, SetDeleteModal} from '../../../types/piv-types';
import {AccountsGetPutPostData} from '../../../../common/types/accounts-get-put-post-res';

type MeetingsGetByIdDataAndPk = MeetingsGetByIdData & {pk: string};

interface AccountPivData extends AccountGetPutPiv {
  user_id: string;
  is_removed?: boolean;
}

type Props =
  | {
      query_data: AccountGetPutData | AccountsGetPutPostData;
      info_type: 'account';
      setDeletePivModal?: never;
      setIsPivDeleteSuccess?: never;
    }
  | {
      query_data: AccountPivData;
      info_type: 'piv';
      setDeletePivModal: SetDeleteModal;
      setIsPivDeleteSuccess: SetIsPivDeleteSuccess;
    }
  | {
      query_data: MeetingsGetByIdDataAndPk;
      info_type: 'meeting';
      setDeletePivModal?: never;
      setIsPivDeleteSuccess?: never;
    }
  | {
      query_data: RegistrantGetResData | RegistrantsGetByUserIdData;
      info_type: 'registrant';
      setDeletePivModal?: never;
      setIsPivDeleteSuccess?: never;
    };

export default function AdditionalInformation({
  query_data,
  info_type,
  setDeletePivModal,
  setIsPivDeleteSuccess,
}: Props) {
  const [isSelectedPiv, setIsSelectedPiv] = useState(false);
  const fields_to_loop: string[] = [];
  if (info_type == 'account') {
    fields_to_loop.push(...account_view_additional_fields);
  } else if (info_type == 'piv') {
    // We aren't passing removed PIV data in the response or displaying removed PIVs to the user,
    // If a PIV's data is in the query, it can be presumed to that is_removed: false
    // This check ensures that we don't duplicate is_removed if the API changes to include that field
    if (!('is_removed' in query_data)) {
      query_data.is_removed = false;
    }
    fields_to_loop.push(...piv_view_additional_fields);
  } else if (info_type == 'meeting') {
    fields_to_loop.push(...meeting_view_additional_fields);
  } else {
    fields_to_loop.push(...registrant_view_additional_fields);
  }

  function handleDeletePiv() {
    if (!setDeletePivModal) throw new Error('PIV deletion handler undefined');
    setIsPivDeleteSuccess(null);
    setIsSelectedPiv(true);
    setDeletePivModal({
      isModalOpen: true,
      pk: query_data.pk,
      serial: query_data.piv_serialnumber,
      user_id: query_data.user_id,
      setIsSelectedPiv: setIsSelectedPiv,
    });
  }

  return (
    <section className="padding-2 bg-internal">
      {info_type != 'piv' && <h2 className="font-sans-md margin-top-0">Additional information</h2>}
      <table
        className={`usa-table usa-table--stacked-header bg-white width-full margin-0 font-sans-sm${
          isSelectedPiv && ' border-secondary border-05'
        }`}
        data-testid={`${info_type}addlInfo`}
      >
        <tbody>
          {fields_to_loop.map((field, idx) => {
            let field_val, title;
            if (info_type == 'account') {
              field_val = query_data[field as keyof AccountGetPutData];
              title = metadata_client_account[field as keyof typeof metadata_client_account].title;
            } else if (info_type == 'piv') {
              field_val = query_data[field as keyof AccountGetPutPiv];
              title = metadata_client_piv[field as keyof typeof metadata_client_piv].title;
            } else if (info_type == 'meeting') {
              field_val = query_data[field as keyof MeetingsGetByIdDataAndPk];
              title = metadata_client_meeting[field as keyof typeof metadata_client_meeting].title;
            } else {
              field_val = query_data[field as keyof RegistrantsGetByUserIdData];
              // Registrant metadata reuses fields from account metadata
              title = metadata_client_account[field as keyof typeof metadata_client_account].title;
            }
            return (
              <Fragment key={idx}>
                {field in query_data && (
                  <tr>
                    <td>
                      <span className="text-semibold">{title}</span>
                    </td>
                    {field == 'is_disabled' || field == 'is_removed' ? (
                      // To Invert the colors since "true" for is_disabled or is_removed is a "bad" thing
                      <td>
                        <Tag className={field_val ? 'bg-error-dark' : 'bg-success-dark'}>
                          {`${field_val as string}`}
                        </Tag>
                      </td>
                    ) : (
                      <td className="word-break-all">
                        {Array.isArray(field_val) ? (
                          field == 'login_gov_emails' && field_val.length == 0 ? (
                            'N/A'
                          ) : (
                            (field_val as string[]).join(', ')
                          )
                        ) : field_val != null ? (
                          // Because field_val can be null, true, or false, it's important to check the boolean in addition to checking for null
                          typeof field_val == 'boolean' ? (
                            <Tag className={field_val ? 'bg-success-dark' : 'bg-error-dark'}>
                              {field_val.toString()}
                            </Tag>
                          ) : (
                            `${field_val as string}`
                          )
                        ) : (
                          'N/A'
                        )}
                      </td>
                    )}
                  </tr>
                )}
              </Fragment>
            );
          })}
          {info_type == 'piv' && (
            <tr>
              <td colSpan={2} className="text-right">
                <Button
                  type="button"
                  className="usa-button--outline usa-button--outline-secondary margin-right-0"
                  onClick={handleDeletePiv}
                >
                  Delete this PIV certificate
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
}

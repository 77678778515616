import {useEffect, useState} from 'react';
import {addOrRemoveValidationMessage, isEmailValid} from '../../../lib/utils';
import {Label, TextInput} from '@trussworks/react-uswds';
import metadata_client_broadcast from '../../../lib/metadata-client-broadcast.json';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import {
  FormType,
  HandleInputChange,
  MetadataValue,
  SetValidationMessages,
} from '../../../types/form-types';
import SectionDescription from '../SectionDescription/SectionDescription';

interface Props {
  id_name: string;
  input: string;
  handleChange: HandleInputChange;
  setValidationMessages: SetValidationMessages;
  is_required?: boolean;
  is_disabled?: boolean;
  custom_desc?: string;
  form_type: FormType;
}

export default function FormEmailInput({
  id_name,
  input,
  handleChange,
  setValidationMessages,
  is_required,
  is_disabled,
  custom_desc,
  form_type,
}: Props) {
  const [isValid, setIsValid] = useState(true);
  let metadata_obj: MetadataValue;
  if (form_type == 'broadcast') {
    metadata_obj = metadata_client_broadcast[id_name as keyof typeof metadata_client_broadcast];
  } else {
    metadata_obj = metadata_client_account[id_name as keyof typeof metadata_client_account];
  }

  let validation_message!: string;
  if (!('validation_message' in metadata_obj)) {
    throw new Error(
      `Please add "validation_message" in metadata JSON for required properties in ${id_name}`
    );
  } else {
    validation_message = metadata_obj.validation_message;
  }

  useEffect(() => {
    const is_valid = input ? isEmailValid(input) : !is_required;
    addOrRemoveValidationMessage({
      is_condition_met: !is_valid,
      err_message: validation_message,
      setValidationMessages,
    });

    setIsValid(is_valid);
  }, [validation_message, input, is_required, setValidationMessages]);

  return (
    <>
      <Label htmlFor={id_name} className="maxw-none">
        <span className="text-primary text-semibold">
          {metadata_obj.title}
          {is_required && ' *'}
        </span>
      </Label>
      {custom_desc && <SectionDescription>{custom_desc}</SectionDescription>}
      <TextInput
        id={id_name}
        name={id_name}
        type="text"
        value={input}
        onChange={handleChange}
        validationStatus={isValid ? undefined : 'error'}
        disabled={is_disabled}
        required={is_required}
      />
    </>
  );
}

import {Checkbox, Icon, Tag} from '@trussworks/react-uswds';
import styles from './MeetingFiles.module.scss';
import Accordion, {AccordionItem} from '../../Accordion/Accordion';
import {
  addOrRemoveValidationMessage,
  meeting_file_message_dict,
  getUniqueFileId,
  mtg_files_max_qty,
  mtg_files_exceeded_max_qty_validation_msg,
  mtg_files_maxed_qty_btn_tooltip_msg,
} from '../../../lib/utils';
import {SetValidationMessages} from '../../../types/form-types';
import {Tooltip} from 'react-tooltip';
import MeetingFile from '../MeetingFile/MeetingFile';
import SectionTitle from '../../FormComponents/SectionTitle/SectionTitle';
import {FilesInputDict, MtgFormFile} from '../../../types/meeting-types';
import DeleteFileModal from '../DeleteFileModal/DeleteFileModal';
import {ChangeEvent, useState} from 'react';
import {useEffect} from 'react';
import {v4 as uuidV4} from 'uuid';
import {MtgFileCategoriesGetResCategory} from '../../../../common/types/meeting-file-categories-get-res';
import {DateTime} from 'luxon';
import ReplaceFileModal from '../ReplaceFileModal/ReplaceFileModal';
import DnDContextProvider, {DnDItem} from '../../SortableComponents/DnDContextProvider';

export type SetFiles = React.Dispatch<React.SetStateAction<MtgFormFile[]>>;

interface Props {
  files: MtgFormFile[];
  setFiles: SetFiles;
  categories: MtgFileCategoriesGetResCategory[];
  file_inputs_ref: React.MutableRefObject<FilesInputDict>;
  setValidationMessages: SetValidationMessages;
  is_edit_mtg?: boolean;
}

export interface MtgFileModal {
  is_open: boolean;
  file_idx: number | null;
}

export type SetDeleteModal = React.Dispatch<React.SetStateAction<MtgFileModal>>;
export type SetReplaceModal = React.Dispatch<React.SetStateAction<MtgFileModal>>;

const initial_modal: MtgFileModal = {
  is_open: false,
  file_idx: null,
};

export type SetInvalidFileIds = React.Dispatch<React.SetStateAction<string[]>>;
export type SetDeleteEnabledIds = React.Dispatch<React.SetStateAction<string[]>>;

export default function MeetingFiles({
  files,
  setFiles,
  categories,
  file_inputs_ref,
  setValidationMessages,
  is_edit_mtg,
}: Props) {
  const [deleteFileModal, setDeleteFileModal] = useState<MtgFileModal>(initial_modal);
  const [replaceFileModal, setReplaceFileModal] = useState<MtgFileModal>(initial_modal);
  const [invalidFileIds, setInvalidFileIds] = useState<string[]>([]);
  const [deleteEnabledIds, setDeleteEnabledIds] = useState<string[]>([]);
  const [isSortMode, setIsSortMode] = useState(false);
  const [dndItems, setDndItems] = useState<DnDItem[]>([]);

  const files_qty = files.filter((file) => !file.is_removed).length;
  const has_maxed_files = files_qty >= mtg_files_max_qty;
  const can_sort = files_qty > 1;

  useEffect(() => {
    const has_exceeded_max_files = files_qty > mtg_files_max_qty;
    addOrRemoveValidationMessage({
      is_condition_met: has_exceeded_max_files,
      err_message: mtg_files_exceeded_max_qty_validation_msg,
      setValidationMessages,
    });
  }, [files_qty, setValidationMessages]);

  useEffect(() => {
    addOrRemoveValidationMessage({
      is_condition_met: isSortMode,
      err_message: 'Please complete sorting files',
      setValidationMessages,
    });
  }, [isSortMode, setValidationMessages]);

  useEffect(() => {
    const all_local_ids = files.map((file) => file.local_id);
    setInvalidFileIds((ids) => ids.filter((id) => all_local_ids.includes(id)));
    setDeleteEnabledIds((ids) => ids.filter((id) => all_local_ids.includes(id)));
  }, [files]);

  const handleAddFile = () => {
    if (has_maxed_files) return;

    const new_file: MtgFormFile = {
      category_pk: '',
      file_name: null,
      title: '',
      date: DateTime.now().toISODate(),
      order: Math.max(-1, ...files.map((file) => file.order)) + 1,
      summary: '',
      editor_notes: '',
      is_hidden: false,
      upload_id: uuidV4(),
      local_id: uuidV4(),
      original_file_name: null,
      signature_mismatch: false,
      uploaded_file_name: null,
    };
    setFiles([...files, new_file]);
  };

  function handleDeleteFile() {
    const idx = deleteFileModal.file_idx;
    if (idx != null) {
      setFiles((files) => {
        const new_files = [...files];
        const new_file = {...new_files[idx]};
        new_file.is_removed = true;
        new_files[idx] = new_file;
        return new_files;
      });
    }
  }

  function handleReplaceFile() {
    const idx = replaceFileModal.file_idx;
    if (idx == null) return;
    // file_inputs_ref maintains a dictionary of file selection inputs by upload_id
    const {upload_id} = files[idx];
    file_inputs_ref.current[upload_id]?.click();
  }

  const accordion_items: AccordionItem<MtgFormFile>[] = files.map((file, idx) => {
    const display_category =
      categories.find((category) => category.pk == file.category_pk)?.title || '(Select Category)';

    return {
      id: getUniqueFileId(file),
      original_item: file,
      prefix: `${idx + 1}. `,
      title: (
        <>
          {display_category}: {file.title || meeting_file_message_dict.untitled_title}
          {is_edit_mtg && !file.pk && <Tag className="bg-violet margin-left-1">New</Tag>}
          {is_edit_mtg && file.is_removed && (
            <Tag className="bg-secondary-dark margin-left-1">Marked for deletion</Tag>
          )}
          {is_edit_mtg && !file.is_removed && !!file.pk && !file.uploaded_file_name && (
            <span className="margin-left-1 text-error text-italic text-semibold">
              File missing - please re-upload file!
            </span>
          )}
        </>
      ),
      content: (
        <MeetingFile
          file={file}
          file_idx={idx}
          num_files={files.length}
          setFiles={setFiles}
          categories={categories}
          file_inputs_ref={file_inputs_ref}
          setValidationMessages={setValidationMessages}
          setInvalidFileIds={setInvalidFileIds}
          setDeleteEnabledIds={setDeleteEnabledIds}
          setDeleteFileModal={setDeleteFileModal}
          setReplaceFileModal={setReplaceFileModal}
          is_edit_mtg={is_edit_mtg}
        />
      ),
      is_initially_expanded: !file.pk, // File renders expanded if it isn't an existing file
      is_invalid: invalidFileIds.includes(file.local_id),
      is_disabled: is_edit_mtg && file.is_removed,
    };
  });

  function handleSortCheckChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.checked) {
      setDndItems(
        accordion_items
          .filter((item) => !item.is_disabled)
          .map((item) => {
            return {
              id: item.original_item.local_id,
              content: (
                <div className="margin-y-1">
                  <div className={`usa-accordion__button word-break-word ${styles.sortable}`}>
                    {item.title}
                  </div>
                </div>
              ),
            };
          })
      );
    } else {
      setFiles((prev_files) => {
        const ordered_files: MtgFormFile[] = [];
        const disabled_files: MtgFormFile[] = [];
        const missing_files: MtgFormFile[] = [];

        prev_files.forEach((file) => {
          if (file.is_removed) return disabled_files.push({...file});

          const dnd_idx = dndItems.findIndex((item) => item.id == file.local_id);
          if (dnd_idx < 0) return missing_files.push({...file});

          ordered_files.push({...file, order: dnd_idx});
        });
        ordered_files.sort((file_a, file_b) => file_a.order - file_b.order);

        // Update order prop for any missing files to maintain that order when meeting is saved
        const new_files = [...ordered_files, ...missing_files, ...disabled_files];
        new_files.forEach((file, idx) => {
          file.order = idx;
        });

        // Ensure that files ordered by the user are displayed first in the correct order, followed by any
        // files that may have been missing from dndItems, and lastly any files marked by the user for removal
        return new_files;
      });
    }
    setIsSortMode((mode) => !mode);
  }

  return (
    <section className="margin-top-3">
      <SectionTitle>Files</SectionTitle>
      {!!files.length && (
        <>
          {can_sort && (
            <>
              <Checkbox
                id="toggle-file-sort-checkbox"
                name="toggle-file-sort-checkbox"
                label={isSortMode ? 'Uncheck to complete sorting' : 'Check to sort files'}
                onChange={handleSortCheckChange}
              />
              {isSortMode && (
                <section className="usa-accordion margin-top-1">
                  <DnDContextProvider items={dndItems} setItems={setDndItems} />
                </section>
              )}
            </>
          )}
          <div className={isSortMode ? 'display-none' : ''}>
            <Accordion accordion_items={accordion_items} />
          </div>
        </>
      )}
      <DeleteFileModal
        isModalOpen={deleteFileModal.is_open}
        closeModal={() => setDeleteFileModal(initial_modal)}
        file_title={
          (deleteFileModal.file_idx != null && files[deleteFileModal.file_idx].title) ||
          meeting_file_message_dict.untitled_title
        }
        handleDeleteFile={handleDeleteFile}
        is_btn_enabled={
          deleteFileModal.file_idx != null &&
          deleteEnabledIds.includes(files[deleteFileModal.file_idx].local_id)
        }
      />
      <ReplaceFileModal
        isModalOpen={replaceFileModal.is_open}
        closeModal={() => setReplaceFileModal(initial_modal)}
        file_name={
          (replaceFileModal.file_idx != null &&
            files[replaceFileModal.file_idx].uploaded_file_name) ||
          '(unknown file name)' // Shouldn't get here since uploaded_file_name is a condition for modal trigger
        }
        handleReplaceFile={handleReplaceFile}
      />
      <section className="display-flex flex-wrap">
        <div className="width-full mobile-lg:width-auto">
          <button
            id="add-file-btn"
            type="button"
            className={`${styles.add_file_btn} usa-button usa-button--outline margin-right-2`}
            onClick={() => handleAddFile()}
            aria-disabled={has_maxed_files || isSortMode}
          >
            <Icon.AddCircleOutline className="margin-right-1 flex-shrink-0" role="presentation" />
            Add a file
          </button>
          {(has_maxed_files || isSortMode) && (
            <Tooltip anchorSelect="#add-file-btn" place="bottom">
              <ul className="usa-list usa-list--unstyled">
                {has_maxed_files && (
                  <li key="maxed_files">{mtg_files_maxed_qty_btn_tooltip_msg}</li>
                )}
                {isSortMode && <li key="sort_mode">Complete sorting to add or edit files</li>}
              </ul>
            </Tooltip>
          )}
        </div>
      </section>
    </section>
  );
}

import {isEmailValid} from '../../../../../lib/utils';

interface Props {
  emails: string[];
  validate_emails?: boolean;
}

export default function EmailPills({emails, validate_emails}: Props) {
  return (
    <section className="word-break-word" data-testid="cc-emails-pills">
      {emails.map((email, idx) => {
        const is_email_valid = !validate_emails || isEmailValid(email);
        return (
          <p
            key={idx}
            // eslint-disable-next-line max-len
            className={`display-inline-block margin-top-0 margin-right-105 margin-bottom-1 border radius-lg padding-x-1 padding-top-1px padding-bottom-2px line-height-sans-3${
              !is_email_valid
                ? ' text-white text-semibold bg-secondary border-secondary'
                : ' text-primary-dark bg-white border-primary'
            }`}
          >
            {email}
          </p>
        );
      })}
    </section>
  );
}

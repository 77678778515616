import {Label, TextInput} from '@trussworks/react-uswds';
import {useEffect, useState} from 'react';
import {
  SetCrqs,
  SetDeleteModal,
  SetInvalidCroIds,
  SetInvalidCrqIdsBySource,
} from '../CustomRegQuestions/CustomRegQuestions';
import styles from './CustomRegOptions.module.scss';
import {addOrRemoveValidationMessage, getUniqueCrqId} from '../../../lib/utils';
import {SetValidationMessages} from '../../../types/form-types';
import {Tooltip} from 'react-tooltip';
import SectionDescription from '../../FormComponents/SectionDescription/SectionDescription';
import SectionTitle from '../../FormComponents/SectionTitle/SectionTitle';
import CustomRegOption from '../CustomRegOption/CustomRegOption';
import {MtgFormCRQuestionRadio} from '../../../types/meeting-types';
import InlineAlert from '../../FormComponents/InlineAlert/InlineAlert';
import {v4 as uuidV4} from 'uuid';

interface Props {
  setCrqs: SetCrqs;
  crq: MtgFormCRQuestionRadio;
  crq_idx: number;
  setValidationMessages: SetValidationMessages;
  setDeleteCRModal: SetDeleteModal;
  setInvalidCrqIdsBySource: SetInvalidCrqIdsBySource;
  setInvalidCroIds: SetInvalidCroIds;
  is_edit_mtg?: boolean;
}

export default function CustomRegOptions({
  setCrqs,
  crq,
  crq_idx,
  setValidationMessages,
  setDeleteCRModal,
  setInvalidCrqIdsBySource,
  setInvalidCroIds,
  is_edit_mtg,
}: Props) {
  const [newOption, setNewOption] = useState('');
  const cr_options = crq.cr_options;

  const new_cro_id = `${getUniqueCrqId(crq)}-new-cro`;
  const new_cro_btn_id = `${new_cro_id}-button`;

  const non_removed_cros = cr_options.filter((cro) => !cro.is_removed);
  const cros_qty = non_removed_cros.length;
  const has_maxed_cros = cros_qty >= 15;
  const has_exceeded_max_cros = cros_qty > 15;
  const is_new_option_too_long = newOption.length > 255;
  const are_all_options_disabled = cros_qty > 0 && non_removed_cros.every((cro) => cro.is_disabled);
  const is_crq_required_while_cros_disabled = are_all_options_disabled && crq.is_required;
  const is_crq_removed = is_edit_mtg && crq.is_removed;

  const qst_prefix = `Question ${crq_idx + 1}`;
  const cr_options_validation_msg = `Please add at least one radio button option to ${qst_prefix}`;
  const exceeded_max_cros_validation_msg = `${qst_prefix} has more than 15 radio button options. Please delete excess options`;
  const maxed_cros_tooltip_msg = 'Only 15 radio button options may be added to each question';
  const required_crq_disabled_cros_validation_msg = `${qst_prefix} cannot be marked as required because all of its radio button options are disabled`;

  useEffect(() => {
    let is_valid = true;

    is_valid &&= !!cros_qty;
    addOrRemoveValidationMessage({
      is_condition_met: !cros_qty,
      err_message: cr_options_validation_msg,
      setValidationMessages,
    });

    is_valid &&= !has_exceeded_max_cros;
    addOrRemoveValidationMessage({
      is_condition_met: has_exceeded_max_cros,
      err_message: exceeded_max_cros_validation_msg,
      setValidationMessages,
    });

    is_valid &&= !is_crq_required_while_cros_disabled;
    addOrRemoveValidationMessage({
      is_condition_met: is_crq_required_while_cros_disabled,
      err_message: required_crq_disabled_cros_validation_msg,
      setValidationMessages,
    });

    is_valid &&= !is_crq_required_while_cros_disabled;
    addOrRemoveValidationMessage({
      is_condition_met: is_crq_required_while_cros_disabled,
      err_message: required_crq_disabled_cros_validation_msg,
      setValidationMessages,
    });

    setInvalidCrqIdsBySource((source_dict) => {
      const new_source_dict = {...source_dict};
      const new_ids = new_source_dict.from_cro_validation.filter((id) => id != crq.local_id);
      if (!is_valid) new_ids.push(crq.local_id);
      new_source_dict.from_cro_validation = new_ids;
      return new_source_dict;
    });
  }, [
    crq.local_id,
    cr_options_validation_msg,
    cros_qty,
    is_crq_required_while_cros_disabled,
    required_crq_disabled_cros_validation_msg,
    has_exceeded_max_cros,
    exceeded_max_cros_validation_msg,
    qst_prefix,
    setValidationMessages,
    setInvalidCrqIdsBySource,
  ]);

  function handleAddOption() {
    if (!newOption.length || is_new_option_too_long || has_maxed_cros) {
      return;
    }

    // Capture the title now so that we don't end up with a race between setCrqs(...) and setNewOption('')
    const new_option_title = newOption;

    setCrqs((crqs) => {
      const new_crqs = [...crqs];
      const new_crq = {...new_crqs[crq_idx]};
      if (!new_crq.cr_options) throw new Error(`CROs in CRQ[${crq_idx}] are missing`);
      const new_cros = [...new_crq.cr_options];
      const highest_existing_order = new_cros.reduce(
        (max, curr_cr_option) => Math.max(max, curr_cr_option.order),
        -1
      );
      new_cros.push({
        title: new_option_title,
        order: highest_existing_order + 1,
        is_disabled: false,
        disabled_note: null,
        local_id: uuidV4(),
      });
      new_crq.cr_options = new_cros;
      new_crqs[crq_idx] = new_crq;
      return new_crqs;
    });

    setNewOption('');
  }

  return (
    <section className="margin-top-3">
      <SectionTitle is_disabled={is_crq_removed}>Radio button options *</SectionTitle>
      {is_crq_removed ? (
        <InlineAlert>
          Un-check the &quot;Delete Question&quot; button below to retain this question and see its
          radio button options.
        </InlineAlert>
      ) : (
        <>
          <SectionDescription>
            Registrants will be able to select one option to answer the question.
          </SectionDescription>
          <section className="margin-top-1 padding-2 bg-white">
            {cr_options.map((option, idx) => {
              return (
                <CustomRegOption
                  key={idx}
                  setCrqs={setCrqs}
                  crq={crq}
                  crq_idx={crq_idx}
                  cro={option}
                  cro_idx={idx}
                  num_cros={cr_options.length}
                  setValidationMessages={setValidationMessages}
                  setDeleteCRModal={setDeleteCRModal}
                  setInvalidCroIds={setInvalidCroIds}
                  is_edit_mtg={is_edit_mtg}
                />
              );
            })}
            {!cros_qty && (
              <div className="display-flex flex-justify-center flex-align-center padding-1 margin-bottom-1 border-2px border-dashed border-secondary radius-md text-secondary">
                Please add at least one option
              </div>
            )}
            <section>
              <Label htmlFor={new_cro_id} className="maxw-none margin-top-05">
                <span className="text-primary text-semibold">New option</span>
              </Label>
              <SectionDescription>
                Once a new radio button option is added, its text cannot be updated. However, it may
                be removed or disabled.
              </SectionDescription>
              <div className="display-block flex-justify tablet:display-flex flex-align-end">
                <div className="flex-grow">
                  <TextInput
                    id={new_cro_id}
                    name={new_cro_id}
                    type="text"
                    value={newOption}
                    onChange={(event) => setNewOption(event.target.value)}
                    validationStatus={is_new_option_too_long ? 'error' : undefined}
                    disabled={has_maxed_cros}
                  />
                  {has_maxed_cros && (
                    <Tooltip anchorSelect={`#${new_cro_id}`} place="bottom">
                      {maxed_cros_tooltip_msg}
                    </Tooltip>
                  )}
                </div>
                <div className="margin-left-0 tablet:margin-left-2 margin-top-1 tablet:margin-top-0">
                  <button
                    id={new_cro_btn_id}
                    type="button"
                    className={`usa-button usa-button--outline width-full tablet:width-auto ${styles.btn}`}
                    onClick={handleAddOption}
                    aria-disabled={!newOption.length || is_new_option_too_long || has_maxed_cros}
                  >
                    Add new option
                  </button>
                  {(!newOption.length || is_new_option_too_long || has_maxed_cros) && (
                    <Tooltip anchorSelect={`#${new_cro_btn_id}`} place="bottom">
                      {has_maxed_cros
                        ? maxed_cros_tooltip_msg
                        : !newOption.length
                          ? 'Please enter text for new option'
                          : 'New option cannot be more than 255 characters'}
                    </Tooltip>
                  )}
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </section>
  );
}

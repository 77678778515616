import {useMemo, useState} from 'react';
import {MeetingsGetByIdFile} from '../../../../common/types/meetings-get-by-id-res';
import {ColumnDef, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {Link, Icon} from '@trussworks/react-uswds';
import {DateTime} from 'luxon';
import {Tab, TabList, TabPanel, Tabs, TabsProps} from 'react-tabs';
import TableUI from '../../TableUI/TableUI';
import pdf from '../../../images/extension-icons/pdf.svg';
import doc from '../../../images/extension-icons/doc.svg';
import docx from '../../../images/extension-icons/docx.svg';
import mp4 from '../../../images/extension-icons/mp4.svg';
import ppt from '../../../images/extension-icons/ppt.svg';
import pptx from '../../../images/extension-icons/pptx.svg';
import xls from '../../../images/extension-icons/xls.svg';
import xlsx from '../../../images/extension-icons/xlsx.svg';
import {isTruthy} from '../../../lib/utils';

interface Props {
  meeting_id: string;
  files: MeetingsGetByIdFile[];
}

interface FileLinkData {
  file_name: string | null;
  file_pk: string;
}
const extension_svg_dict = {
  pdf: <img src={pdf} className="height-3" alt="PDF" data-testid="pdfImg" />,
  doc: <img src={doc} className="height-3" alt="DOC" data-testid="docImg" />,
  docx: <img src={docx} className="height-3" alt="DOCX" data-testid="docxImg" />,
  mp4: <img src={mp4} className="height-3" alt="MP4" data-testid="mp4Img" />,
  ppt: <img src={ppt} className="height-3" alt="PPT" data-testid="pptImg" />,
  pptx: <img src={pptx} className="height-3" alt="PPTX" data-testid="pptxImg" />,
  xls: <img src={xls} className="height-3" alt="XLS" data-testid="xlsImg" />,
  xlsx: <img src={xlsx} className="height-3" alt="XLSX" data-testid="xlsxImg" />,
};

export default function ViewMeetingFilesTable({meeting_id, files}: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [filteredFiles, setFilteredFiles] = useState(() => files);

  // Populate the category tabs using only categories the meeting's files have
  const available_tabs = useMemo(() => {
    const tabs = [{id: 'all_files', title: 'All files'}];
    const file_categories = files
      .map((file) => file.category)
      .filter(isTruthy)
      .sort((category_a, category_b) => category_a.title.localeCompare(category_b.title, 'en'));
    file_categories.forEach((category) => {
      if (!tabs.some((tab) => tab.id == category.pk)) {
        tabs.push({id: category.pk, title: category.title});
      }
    });
    return tabs;
  }, [files]);

  const handleTabSelect: TabsProps['onSelect'] = (idx) => {
    if (idx == activeTabIndex) return;

    if (idx == 0) {
      setFilteredFiles(() => files);
    } else {
      const selected_tab = available_tabs[idx];
      setFilteredFiles(() => {
        return files.filter((file) => file.category?.pk == selected_tab.id);
      });
    }

    setActiveTabIndex(idx);
  };

  const columns = useMemo<ColumnDef<MeetingsGetByIdFile>[]>(
    () => [
      {
        accessorKey: 'file_extension',
        accessorFn: (row) => {
          return row.file_name?.split('.').pop()?.toLowerCase() ?? '';
        },
        cell: (info) => {
          const file_extension = info.getValue<string>();
          if (file_extension in extension_svg_dict) {
            return extension_svg_dict[file_extension as keyof typeof extension_svg_dict];
          } else {
            return file_extension;
          }
        },
        header: 'Ext',
      },
      {
        accessorFn: (row) => DateTime.fromISO(row.date).toFormat('DD'),
        header: 'Upload date',
      },
      {
        accessorFn: (row) => `${row.title} (${row.file_name || 'No file name to display'})`,
        header: 'File title',
      },
      {
        accessorFn: (row) => row.summary,
        header: 'Summary',
      },
      {
        accessorFn: (row): FileLinkData => {
          return {file_name: row.file_name, file_pk: row.pk};
        },
        cell: (info) => {
          const {file_name, file_pk} = info.getValue<FileLinkData>();
          if (!file_name) {
            return (
              <p className="text-secondary text-italic text-semibold width-card-lg margin-y-0">
                File missing- please contact meeting organizer
              </p>
            );
          }
          return (
            <Link
              className="usa-button usa-button--outline display-flex margin-right-0"
              href={`/meetings/${meeting_id}/files/${file_pk}/${encodeURI(file_name)}`}
              data-testid={`${file_pk}downloadLink`}
            >
              <span className="display-inline-block margin-left-auto">Download</span>{' '}
              <Icon.FileDownload className="margin-left-05 margin-right-auto" role="presentation" />
            </Link>
          );
        },
        header: undefined,
        id: 'download_link',
      },
    ],
    [meeting_id]
  );

  const table = useReactTable({
    data: filteredFiles,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      getRowClasses: (row) => (row.original.is_hidden ? 'is_hidden' : ''),
    },
  });

  return (
    <>
      <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
        <TabList>
          {available_tabs.map((tab) => (
            <Tab key={tab.id}>{tab.title}</Tab>
          ))}
        </TabList>
        {available_tabs.map((tab) => (
          // Intentionally empty panel
          <TabPanel key={tab.id} />
        ))}
      </Tabs>
      <TableUI
        variant="meeting_files_table"
        table={table}
        clickedIndex={null}
        setClickedIndex={() => undefined}
        is_unsortable
        is_unpaginated
      />
    </>
  );
}

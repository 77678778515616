import {Breadcrumb, BreadcrumbBar, FormGroup, Label, TextInput} from '@trussworks/react-uswds';
import {Link} from 'react-router-dom';
import {
  FlattenedAuthLogGetItem,
  auth_log_title,
  auth_method_dict,
  auth_result_dict,
  fromUTCtoET,
  isInDateTimeFilterKeys,
  time_range_title,
} from '../../lib/utils';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {DateTimeFilter, useQueryAuthLog} from '../../services/session-mgmt-queries';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import {useMemo, useRef, useState} from 'react';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import TableUI from '../../components/TableUI/TableUI';
import metadata_client_auth_log from '../../lib/metadata-client-auth-log.json';
import LongFieldWithTooltip from '../../components/LongFieldWithTooltip/LongFieldWithTooltip';
import TextWithTooltip from '../../components/TextWithTooltip/TextWithTooltip';
import {DateTime} from 'luxon';
import FormDatetimeRange from '../../components/FormComponents/FormDatetimeRange/FormDatetimeRange';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import FormLayout from '../../layouts/FormLayout/FormLayout';

// Default start filter is 135 mins ago
const default_start_datetime: DateTimeFilter['start'] = DateTime.utc()
  .minus({minutes: 135})
  .toISO();
const default_end_datetime: DateTimeFilter['end'] = undefined;

export default function AuthLog() {
  usePageTitle(auth_log_title);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [dateFilters, setDateFilters] = useState<DateTimeFilter>({
    start: default_start_datetime,
    end: default_end_datetime,
  });
  const [appliedDateFilters, setAppliedDateFilters] = useState<DateTimeFilter>(dateFilters);
  const query = useQueryAuthLog(appliedDateFilters);
  const privileges = usePrivilegesContext();
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const submit_btn_ref = useRef<HTMLButtonElement>(null);

  const handleFilterChange = (key: string, value: string | null) => {
    if (!isInDateTimeFilterKeys(['start', 'end'], key)) return;
    setDateFilters((prev_dates) => {
      const new_dates = {...prev_dates};
      new_dates[key] = value ?? undefined;
      return new_dates;
    });
  };

  const handleFilterSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (validationMessages.length || query.isPending) {
      submit_btn_ref.current?.focus();
      return;
    }
    setAppliedDateFilters(dateFilters);
  };

  const flattened_users = useMemo(() => {
    return (query.data ?? []).flatMap((auth_attempt) => {
      const users = auth_attempt.users.length ? auth_attempt.users : [undefined];
      return users.map<FlattenedAuthLogGetItem>((user) => ({...auth_attempt, user}));
    });
  }, [query.data]);

  const columns = useMemo<ColumnDef<FlattenedAuthLogGetItem>[]>(
    () => [
      {
        accessorKey: 'create_datetime',
        cell: (info) => fromUTCtoET(info.getValue<string>(), {use_long_date: true}),
        header: metadata_client_auth_log.create_datetime.title,
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.user?.email ?? '',
        header: metadata_client_auth_log.email.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => auth_method_dict[row.auth_method],
        header: metadata_client_auth_log.auth_method.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => auth_result_dict[row.result].title,
        cell: (info) => {
          const auth_result = auth_result_dict[info.row.original.result];
          if (!auth_result.tooltip) return auth_result.title;
          return (
            <TextWithTooltip
              display_text={`${auth_result.title}...`}
              tooltip_text={auth_result.tooltip}
            />
          );
        },
        header: metadata_client_auth_log.result.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.details ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_auth_log.details.title,
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ip',
        header: metadata_client_auth_log.ip.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.user_agent ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_auth_log.user_agent.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.user?.pk,
        cell: (info) => {
          const user_pk = info.row.original.user?.pk;
          if (!user_pk) return;
          const my_pk = privileges.pk;
          return (
            <Link
              to={user_pk == my_pk ? '/my-account' : `/manage-accounts/account/${user_pk}`}
              className="usa-link text-no-wrap"
              data-user_pk={user_pk == my_pk ? user_pk : undefined}
            >
              {user_pk == my_pk ? 'My account' : 'View account'}
            </Link>
          );
        },
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
        id: 'user_pk',
      },
    ],
    [privileges.pk]
  );

  const table = useReactTable({
    data: flattened_users,
    columns,
    state: {sorting, globalFilter},
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnPinning: {
        right: ['user_pk'],
      },
    },
  });

  // This shouldn't render since we're using err boundary in the query
  if (query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>{auth_log_title}</span>
        </Breadcrumb>
      </BreadcrumbBar>
      <FormLayout handleSubmit={handleFilterSubmit} className="margin-bottom-1">
        <div className="grid-row">
          <FormDatetimeRange
            range_title={time_range_title}
            id_name_1="start"
            id_name_2="end"
            metadata_1={metadata_client_auth_log.start}
            metadata_2={metadata_client_auth_log.end}
            setValidationMessages={setValidationMessages}
            handleChange={handleFilterChange}
            default_value_1={
              default_start_datetime ? {iso_datetime: default_start_datetime} : undefined
            }
            default_value_2={
              default_end_datetime ? {iso_datetime: default_end_datetime} : undefined
            }
            enforce_order
          />
          <div className="margin-top-1 text-right text-bottom flex-align-self-end mobile-lg:grid-col mobile-lg:margin-top-0 mobile-lg:margin-left-105">
            <FormSubmit
              validationMessages={validationMessages}
              ref={submit_btn_ref}
              use_outline_btn
            >
              {time_range_title}
            </FormSubmit>
          </div>
        </div>
      </FormLayout>
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word">
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            Search emails and IPs
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
      </section>
      {query.isPending ? (
        <PageLoading message="Loading authentication log" />
      ) : (
        <TableUI
          table={table}
          clickedIndex={clickedIndex}
          setClickedIndex={setClickedIndex}
          variant="auth_log_table"
        />
      )}
    </>
  );
}

import {Icon, ModalRef, NavCloseButton} from '@trussworks/react-uswds';
import {RefObject, useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import AuthModal from '../../../components/AuthModal/AuthModal';
import AccountBtn from './AccountBtn/AccountBtn';
import styles from './Navbar.module.scss';
import {app_name} from '../../../../common/lib/shared-vars';
import {useQueryPrivileges} from '../../../services/account-queries';
import {PivAssociationTypes} from '../../../types/auth-types';
import PivAssociationBanner from './PivAssociationBanner/PivAssociationBanner';
import AdminTools from './AdminTools/AdminTools';
import {useClickOutside} from '../../../lib/hooks/useClickOutside';
import MeetingsMenu from './MeetingsMenu/MeetingsMenu';

interface Props {
  authModalRef: RefObject<ModalRef>;
}

export type IsSetMobileNavExpanded = React.Dispatch<React.SetStateAction<boolean>>;
export type NavSubMenuState = null | 'admin-tools' | 'profile-menu' | 'meetings-menu';
export type HandleSubMenu = () => void;

export default function Navbar({authModalRef}: Props) {
  const [navSubMenuState, setNavSubMenuState] = useState<NavSubMenuState>(null);
  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);
  const [pivAssociationState, setPivAssociationState] = useState<PivAssociationTypes>(null);
  const location = useLocation();
  // This is the initial magic link route: `/confirmation/token` and `/confirmation` (latter renders "Page not found")
  // Don't display any navbar buttons except the site title on this route & don't run privileges query
  const is_location_confirmation = location.pathname.startsWith('/confirmation');
  // This is the `/confirm-account` route where new users complete account creation
  // If user is !is_verified, will only display the "Sign out" button on this route
  // If user is_verified, route will display a "Page not found" error & appropriate privileges-based links in nav bar
  const is_location_confirm_account = location.pathname == '/confirm-account';
  // Disable useQueryPrivileges for login routes (/confirm/{token} & /confirm-account)
  // to avoid errors & to properly render user info in navbar
  const query = useQueryPrivileges({is_enabled: !is_location_confirmation});
  const wrapperRef = useRef<HTMLElement>(null);
  useClickOutside({
    ref: wrapperRef,
    onClickOutside: () => {
      setNavSubMenuState(null);
      setIsMobileNavExpanded(false);
    },
  });

  // Clear the pivAssociationState (and the banner) when user navigates to new page
  useEffect(() => {
    setPivAssociationState(null);
  }, [location.pathname, query.data?.pk]);

  // Clear the submenu
  useEffect(() => {
    setNavSubMenuState(null);
  }, [location.pathname]);

  const handleSubMenu = (menu_name: NavSubMenuState) => {
    setNavSubMenuState((prevState) => {
      if (prevState == menu_name) return null;
      return menu_name;
    });
  };

  return (
    <>
      <div className={`usa-overlay ${isMobileNavExpanded ? 'is-visible' : ''}`}></div>
      <header className="usa-header usa-header--basic bg-primary">
        <div className={`usa-nav-container desktop:height-10 ${styles.nav_container}`}>
          <div className="usa-navbar">
            <div className="usa-logo">
              <em className="usa-logo__text">
                <Link to="/">{app_name}</Link>
              </em>
            </div>
            {!is_location_confirmation && (
              <button
                type="button"
                className="usa-menu-btn"
                onClick={() => setIsMobileNavExpanded(true)}
              >
                <Icon.Menu size={3} role="presentation" />
              </button>
            )}
          </div>
          <div className={is_location_confirmation ? 'display-none' : 'height-full'}>
            {
              // W/o `!query.isFetchedAfterMount`, the navbar flashes on background refetch
              // W/o `!is_location_confirm`, navbar won't render at `/confirmation/{token}` causing flash &
              // no navbar to render on sign in error or loading states
            }
            {query.isPending && !query.isFetchedAfterMount && !is_location_confirmation ? (
              <></>
            ) : (
              <nav
                aria-label="Primary navigation"
                className={`usa-nav ${
                  isMobileNavExpanded ? 'is-visible' : 'height-8 margin-top-2'
                }`}
                ref={wrapperRef}
              >
                <NavCloseButton onClick={() => setIsMobileNavExpanded(false)} />
                <ul
                  className={`usa-nav__primary usa-accordion margin-top-0 ${
                    isMobileNavExpanded ? '' : 'height-full'
                  } ${
                    is_location_confirm_account && !query.data?.is_verified ? 'display-none' : ''
                  }`}
                >
                  <MeetingsMenu
                    name="meetings-menu"
                    isSubMenuOpen={navSubMenuState == 'meetings-menu'}
                    handleSubMenu={() => handleSubMenu('meetings-menu')}
                    setIsMobileNavExpanded={setIsMobileNavExpanded}
                  />
                  {query.isSuccess && query.data.can_view_users && (
                    <li className={`usa-nav__primary-item ${styles.nav_item}`}>
                      <Link to="manage-accounts" onClick={() => setIsMobileNavExpanded(false)}>
                        Manage accounts
                      </Link>
                    </li>
                  )}
                  {query.isSuccess && (
                    <AdminTools
                      name="admin-tools"
                      isSubMenuOpen={navSubMenuState == 'admin-tools'}
                      handleSubMenu={() => handleSubMenu('admin-tools')}
                      privileges_data={query.data}
                      setIsMobileNavExpanded={setIsMobileNavExpanded}
                    />
                  )}
                </ul>
                {query.isSuccess ? (
                  <AccountBtn
                    name="profile-menu"
                    isSubMenuOpen={navSubMenuState == 'profile-menu'}
                    handleSubMenu={() => handleSubMenu('profile-menu')}
                    setIsMobileNavExpanded={setIsMobileNavExpanded}
                    is_location_confirm_account={is_location_confirm_account}
                    setPivAssociationState={setPivAssociationState}
                  />
                ) : (
                  <div className={styles.btn_wrapper}>
                    <AuthModal authModalRef={authModalRef} />
                  </div>
                )}
              </nav>
            )}
          </div>
        </div>
        <PivAssociationBanner
          association_type={pivAssociationState}
          setPivAssociationState={setPivAssociationState}
        />
      </header>
    </>
  );
}

import {Accordion} from '@trussworks/react-uswds';
import {StatusesGetData} from '../../../../../../common/types/statuses-get-res';
import {capitalizeFirstLetter} from '../../../../../lib/utils';

const status_details = [
  {
    name: 'draft',
    desc: 'Meeting will not display in public meeting list, and only Editors and Admins who are signed into this application may view meeting details. Registration is not open.',
  },
  {
    name: 'tentative',
    desc: 'Meeting will not display in public meeting list, however anyone with the URL will be able to view meeting details. Registration is not open.',
  },
  {name: 'scheduled', desc: 'Meeting will display in public meeting list. Registration is open.'},
  {
    name: 'completed',
    desc: 'Meeting will display in public meeting list. Registration is not open.',
  },
  {
    name: 'finalized',
    desc: 'Meeting will display in public meeting list. Registration is not open.',
  },
  {
    name: 'postponed',
    desc: 'Meeting will display in public meeting list, however meeting has an unknown start date and/or time. In order to update the start or end date/time, a status other than "postponed" must be selected. Registration is not open.',
  },
  {
    name: 'cancelled',
    desc: 'Meeting will display in public meeting list. Registration is not open.',
  },
];

interface Props {
  statuses_data: StatusesGetData;
}

export default function MeetingStatusAccordion({statuses_data}: Props) {
  const accordion_items: React.ComponentProps<typeof Accordion>['items'] = [
    {
      title: 'Click to view status details',
      content: (
        <ul className="status-list">
          {statuses_data.map(({name, pk}) => {
            const details = status_details.find((status) => status.name == name);
            if (details) {
              return (
                <li key={pk}>
                  <span className={`text-bold status-text ${name}-status`}>
                    {capitalizeFirstLetter(name)}:{' '}
                  </span>
                  {details.desc}
                </li>
              );
            }
          })}
        </ul>
      ),
      expanded: false,
      id: 'status-explanation-accordion',
      headingLevel: 'h2',
      className: 'font-sans-xs',
    },
  ];
  return (
    <Accordion
      items={accordion_items}
      className="margin-top-1 margin-bottom-2 status-accordion"
      bordered
    />
  );
}

import {ModalSwitchTypes} from '../../../types/auth-types';
import {Button, ModalRef, ModalToggleButton} from '@trussworks/react-uswds';
import {RefObject} from 'react';
import {useQueryClientConfig} from '../../../services/config-queries';
import PageLoading from '../../LoadingComponents/PageLoading/PageLoading';

interface Props {
  authModalRef: RefObject<ModalRef>;
  setCurrentModal: React.Dispatch<React.SetStateAction<ModalSwitchTypes>>;
}
export default function LegalNotice({authModalRef, setCurrentModal}: Props) {
  const client_config_query = useQueryClientConfig();
  if (client_config_query.isPending) return <PageLoading />;
  if (client_config_query.isError) {
    if (client_config_query.isFetching) return <PageLoading />;
    setCurrentModal('legal-notice-error');
    // This won't render because the modal will have already switched
    return <>Error</>;
  }
  const {legal_notice_caption, legal_notice_text_items} = client_config_query.data;

  return (
    <>
      <h2 className="bg-base-lightest font-sans-sm mobile-lg:font-sans-md margin-top-0 margin-bottom-2 padding-2 word-break-word">
        {legal_notice_caption}
      </h2>
      <section
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className="bg-base-lightest maxh-card-lg overflow-auto margin-bottom-3 padding-2 tab-index=0"
      >
        {legal_notice_text_items.length > 1 ? (
          <ul className="usa-list margin-top-0 padding-left-2">
            {legal_notice_text_items.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        ) : (
          legal_notice_text_items
        )}
      </section>
      <Button
        type="button"
        className="usa-button width-full margin-bottom-1"
        onClick={() => {
          setCurrentModal('auth-index');
        }}
      >
        Accept
      </Button>
      <ModalToggleButton
        modalRef={authModalRef}
        className="usa-button--outline box-shadow-3 width-full"
        closer
      >
        Decline
      </ModalToggleButton>
    </>
  );
}

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

// Layouts
import RootLayout from './layouts/RootLayout/RootLayout';

// Pages
import NotFound from './pages/NotFound/NotFound';
import RootErrorBoundary from './pages/RootErrorBoundary/RootErrorBoundary';
import LoginError from './pages/LoginError/LoginError';
import LoginGovLoginError from './pages/LoginGovLoginError/LoginGovLoginError';
import LoginGovLogoutConfirmation from './pages/LoginGovLogoutConfirmation/LoginGovLogoutConfirmation';
import ManageAccounts from './pages/ManageAccounts/ManageAccounts';
import MyAccountWrapper from './pages/MyAccount/MyAccountWrapper/MyAccountWrapper';
import ConfirmAccountWrapper from './pages/ConfirmAccount/ConfirmAccountWrapper/ConfirmAccountWrapper';
import CreateAccountWrapper from './pages/CreateAccount/CreateAccountWrapper/CreateAccountWrapper';
import ViewOthersAccountWrapper from './pages/ViewOthersAccount/ViewOthersAccountWrapper/ViewOthersAccountWrapper';
import MeetingsList from './pages/MeetingsList/MeetingsList';
import ViewMeetingWrapper from './pages/ViewMeeting/ViewMeetingWrapper/ViewMeetingWrapper';
import Confirmation from './pages/Confirmation/Confirmation';
import RegistrationWrapper from './pages/Registration/RegistrationWrapper/RegistrationWrapper';
// eslint-disable-next-line max-len
import ViewOthersRegistrationWrapper from './pages/ViewOthersRegistration/ViewOthersRegistrationWrapper/ViewOthersRegistrationWrapper';
import CreateMeetingWrapper from './pages/CreateEditMeeting/CreateMeetingWrapper/CreateMeetingWrapper';
import EditMeetingWrapper from './pages/CreateEditMeeting/EditMeetingWrapper/EditMeetingWrapper';
import EditRegistrationWrapper from './pages/EditRegistration/EditRegistrationWrapper/EditRegistrationWrapper';
import UnknownError from './pages/UnknownError/UnkownError';
import PivList from './pages/PivList/PivList';
import EmailQueue from './pages/EmailQueue/EmailQueue';
import ViewEmailWrapper from './pages/ViewEmail/ViewEmailWrapper/ViewEmailWrapper';
import DownloadError from './pages/DownloadError/DownloadError';
import SessionLog from './pages/SessionLog/SessionLog';
import AuthLog from './pages/AuthLog/AuthLog';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<RootErrorBoundary />}>
      <Route path="*" element={<NotFound />} />

      <Route index element={<MeetingsList />} errorElement={<UnknownError />} />

      <Route path="confirmation">
        <Route index element={<NotFound />} />
        <Route path=":token_id" element={<Confirmation />} errorElement={<LoginError />} />
      </Route>

      <Route
        path="confirm-account"
        element={<ConfirmAccountWrapper />}
        errorElement={<LoginError />}
      />

      <Route
        path="login-error"
        element={<LoginGovLoginError />}
        errorElement={<LoginError is_login_gov />}
      />

      <Route
        path="logout-confirmation"
        element={<LoginGovLogoutConfirmation />}
        errorElement={<UnknownError />}
      />

      <Route path="meetings/:id">
        <Route index element={<ViewMeetingWrapper />} errorElement={<NotFound />} />
        <Route element={<PrivateRoute />}>
          <Route path="edit">
            <Route
              index
              element={
                <ProtectedRoute can_edit_mtg>
                  <EditMeetingWrapper />
                </ProtectedRoute>
              }
              errorElement={<NotFound />}
            />
          </Route>
        </Route>
        <Route
          path="files/:file_id/:file_name"
          element={<DownloadError />}
          errorElement={<NotFound />}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        {/* Account paths */}
        <Route path="my-account" element={<MyAccountWrapper />} />
        <Route path="manage-accounts">
          <Route
            index
            element={
              <ProtectedRoute can_view_users>
                <ManageAccounts />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-account"
            element={
              <ProtectedRoute can_create_users>
                <CreateAccountWrapper />
              </ProtectedRoute>
            }
          />
          <Route
            path="account/:id"
            element={
              <ProtectedRoute can_view_users>
                <ViewOthersAccountWrapper />
              </ProtectedRoute>
            }
            errorElement={<NotFound />}
          />
        </Route>
        {/* Meeting paths */}
        <Route
          path="create-meeting"
          element={
            <ProtectedRoute can_create_mtg>
              <CreateMeetingWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path="meetings/:id/register"
          element={<RegistrationWrapper />}
          errorElement={<NotFound />}
        />
        <Route
          path="meetings/:id/edit-registration"
          element={<EditRegistrationWrapper />}
          errorElement={<NotFound />}
        />
        <Route
          path="meetings/:meeting_id/registrants/:user_id"
          element={
            <ProtectedRoute can_view_registrants>
              <ViewOthersRegistrationWrapper />
            </ProtectedRoute>
          }
          errorElement={<NotFound />}
        />
        {/* Admin Tools paths */}
        <Route path="email-queue">
          <Route
            index
            element={
              <ProtectedRoute can_view_email_queue>
                <EmailQueue />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute can_view_email_queue>
                <ViewEmailWrapper />
              </ProtectedRoute>
            }
            errorElement={<NotFound />}
          />
        </Route>
        <Route
          path="manage-pivs"
          element={
            <ProtectedRoute can_view_piv_data>
              <PivList />
            </ProtectedRoute>
          }
        />
        <Route
          path="session-log"
          element={
            <ProtectedRoute can_manage_sessions>
              <SessionLog />
            </ProtectedRoute>
          }
        />
        <Route
          path="auth-log"
          element={
            <ProtectedRoute can_manage_sessions>
              <AuthLog />
            </ProtectedRoute>
          }
        />
      </Route>
    </Route>
  )
);
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

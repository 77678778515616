/* eslint-disable max-len */
import {Fragment} from 'react';
import {Table, flexRender} from '@tanstack/react-table';
import {Icon, Pagination, Select} from '@trussworks/react-uswds';
import styles from './TableUI.module.scss';
import {TableVariant} from '../../lib/utils';

interface Props<T> {
  table: Table<T>;
  clickedIndex: number | null;
  setClickedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  is_unsortable?: boolean;
  is_unpaginated?: boolean;
  /** Optionally indicate the table variant to load variant-specific styles */
  variant?: TableVariant;
}

export default function TableUI<T>({
  table,
  clickedIndex,
  setClickedIndex,
  is_unsortable,
  is_unpaginated,
  variant,
}: Props<T>) {
  const variant_styles = variant && variant in styles ? styles[variant] : '';
  return (
    <>
      <section className="overflow-y-hidden">
        <table
          className={`usa-table usa-table--borderless width-full usa-table--stacked margin-top-4 ${variant_styles}`}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={styles.head_row}>
                {headerGroup.headers.map((header, index) => {
                  const sort_direction = !is_unsortable && header.column.getIsSorted();
                  const render = flexRender(header.column.columnDef.header, header.getContext());
                  return (
                    <Fragment key={header.id}>
                      {!is_unsortable && header.column.getCanSort() ? (
                        <th
                          scope="col"
                          aria-label={`${
                            header.column.columnDef.header as string
                          }, sortable column, currently ${
                            sort_direction
                              ? sort_direction == 'asc'
                                ? 'ascending'
                                : 'descending'
                              : 'unsorted'
                          }`}
                          // Has to be 'undefined' per USWDS design
                          aria-sort={
                            clickedIndex == index
                              ? sort_direction == 'asc'
                                ? 'ascending'
                                : sort_direction == 'desc'
                                  ? 'descending'
                                  : undefined
                              : undefined
                          }
                          className={
                            header.column.getIsPinned() == 'right'
                              ? styles.pinned_right
                              : 'position-relative'
                          }
                          data-sortable
                        >
                          <button
                            className="usa-button usa-button--unstyled grid-row flex-align-center flex-no-wrap text-no-wrap text-ink text-bold text-no-underline hover:text-ink hover:text-no-underline font-sans-xs"
                            onClick={header.column.getToggleSortingHandler()}
                            onClickCapture={() => setClickedIndex(index)}
                          >
                            {render}
                            {{
                              asc: <Icon.ArrowUpward role="presentation" />,
                              desc: <Icon.ArrowDownward role="presentation" />,
                            }[sort_direction as string] ?? <Icon.SortArrow role="presentation" />}
                          </button>
                        </th>
                      ) : (
                        <th
                          className={
                            header.column.getIsPinned() == 'right'
                              ? styles.pinned_right
                              : 'position-relative'
                          }
                        >
                          {render}
                        </th>
                      )}
                    </Fragment>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`${styles.body_row} ${
                  table.options.meta?.getRowClasses ? table.options.meta.getRowClasses(row) : ''
                }`}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    data-sort-active={(clickedIndex == index && cell.column.getIsSorted()) || null}
                    data-label={
                      typeof cell.column.columnDef.header == 'string'
                        ? cell.column.columnDef.header
                        : undefined
                    }
                    className={`font-sans-xs minh-6 ${
                      cell.column.getIsPinned() == 'right'
                        ? styles.pinned_right
                        : 'position-relative'
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {!is_unpaginated && (
        <section className="grid-row flex-column flex-no-wrap tablet:flex-row flex-justify flex-align-center tablet:margin-top-2">
          <span className="margin-bottom-2">
            {table.getPrePaginationRowModel().rows.length} total entries
          </span>
          {table.getPageCount() > 1 && (
            <Pagination
              maxSlots={7}
              totalPages={table.getPageCount()}
              currentPage={table.getState().pagination.pageIndex + 1}
              onClickNext={() => table.nextPage()}
              onClickPrevious={() => table.previousPage()}
              onClickPageNumber={(e, page) => table.setPageIndex(page - 1)}
              pathname={''}
              className="margin-top-0 margin-bottom-2"
            />
          )}
          <Select
            id="pagination-dropdown"
            name="pagination-dropdown"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="tablet:width-auto margin-top-0 margin-bottom-2"
          >
            {[10, 20, 40].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </section>
      )}
    </>
  );
}

import {useRef, useState} from 'react';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import {
  acc_update_success,
  capitalizeFirstLetter,
  handleTypedInputChange,
  handleTypedPhoneChange,
  piv_delete_success,
  scrollToTop,
} from '../../lib/utils';
import {EditOthersAccountForm} from '../../types/account-types';
import {Grid, Label, TextInput} from '@trussworks/react-uswds';
import FormCheckbox, {
  handleTypedCheckbox,
} from '../../components/FormComponents/FormCheckbox/FormCheckbox';
import FormPhoneInputLazy from '../../components/FormComponents/FormPhoneInput/FormPhoneInputLazy';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import RoleNameTag from '../../components/AccountComponents/RoleNameTag/RoleNameTag';
import AdditionalInformation from '../../components/AccountComponents/AdditionalInformation/AdditionalInformation';
import RoleDropdown, {
  handleTypedDropdownChange,
} from '../../components/FormComponents/AccountFormComponents/RoleDropdown/RoleDropdown';
import {RolesGetData} from '../../../common/types/roles-get-res';
import metadata_client_account from '../../lib/metadata-client-account.json';
import {useMutationEditOthersAccount} from '../../services/account-queries';
import {AccountsGetPutPostData} from '../../../common/types/accounts-get-put-post-res';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import ConfirmAccountDeletionModal, {
  handleTypedDeleteCheckbox,
  handleTypedDeleteConfirmation,
} from '../../components/FormComponents/AccountFormComponents/ConfirmAccountDeletionModal/ConfirmAccountDeletionModal';
import {useNavigate} from 'react-router-dom';
import {ManageAccountsAlertState} from '../../types/meeting-types';
import {AccountPivDetails} from '../../components/AccountComponents/AccountPivDetails/AccountPivDetails';
import LinkedLoginGovInfo from '../../components/AccountComponents/LinkedLoginGovInfo/LinkedLoginGovInfo';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';
import {AccountGetPutData} from '../../../common/types/account-get-put-res';

function formatQueryData(query_data: AccountGetPutData, roles_data: RolesGetData) {
  return {
    first_name: query_data.first_name || '',
    last_name: query_data.last_name || '',
    job_title: query_data.job_title || '',
    org: query_data.org || '',
    phone: query_data.phone || '',
    is_removed: query_data.is_removed,
    is_disabled: query_data.is_disabled || false,
    admin_notes: query_data.admin_notes || '',
    role_fk: roles_data.find((role) => role.name == query_data.role.name)?.pk,
  };
}

interface Props {
  query_data: AccountsGetPutPostData;
  roles_data: RolesGetData;
}

export default function ViewOthersAccount({query_data, roles_data}: Props) {
  usePageTitle('Account info');
  const [formData, setFormData] = useState<EditOthersAccountForm>(
    formatQueryData(query_data, roles_data)
  );
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const [isNavigationPending, setIsNavigationPending] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPivDeleteSuccess, setIsPivDeleteSuccess] = useState<true | null>(null);
  const privileges = usePrivilegesContext();
  const mutation = useMutationEditOthersAccount({is_acc_delete: formData.is_removed});
  const navigate = useNavigate();

  const [initialForm, setInitialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  const is_submit_disabled =
    !!validationMessages.length || mutation.isPending || isNavigationPending;
  const is_discard_disabled = mutation.isPending || isNavigationPending;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    setIsPivDeleteSuccess(null);
    // In MyAccount, this needs to be mutateAsync() instead of mutate() to handle a potential "all query reset". While
    // that isn't an issue here, for easy comparisons between the two components, the same mutation pattern is followed.
    mutation
      .mutateAsync({form: formData, user_id: query_data.pk})
      .then((updated_query_data) => {
        if (formData.is_removed) {
          setIsNavigationPending(true);
          navigate('/manage-accounts', {
            state: {is_acc_deleted: true} as ManageAccountsAlertState,
          });
        } else {
          const updated_form_data = formatQueryData(updated_query_data, roles_data);
          setFormData(updated_form_data);
          setInitialForm(updated_form_data);
          scrollToTop();
        }
      })
      .catch(() => {
        // do nothing w/ the err because it's covered by `mutation.isError` below.
      });
  };

  function handleSuccessfulPivDelete() {
    mutation.reset();
    setIsPivDeleteSuccess(true);
    scrollToTop();
  }

  const handleInputChange = handleTypedInputChange(setFormData);
  const handleCheckbox = handleTypedCheckbox(setFormData);
  const handlePhoneChange = handleTypedPhoneChange(setFormData);
  const handleDropdownChange = handleTypedDropdownChange(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);
  const handleDeleteConfirmation = handleTypedDeleteConfirmation(setFormData, setIsDeleteModalOpen);
  const handleDeleteCheckbox = handleTypedDeleteCheckbox(handleCheckbox, setIsDeleteModalOpen);

  return (
    <section
      className={`grid-container-tablet padding-x-0${
        privileges.can_edit_users ? ' margin-bottom-10' : ''
      }`}
    >
      {mutation.isSuccess && <SuccessAlert>{acc_update_success}</SuccessAlert>}
      {mutation.isError && <FormGenericError error={mutation.error} />}
      {isPivDeleteSuccess && <SuccessAlert>{piv_delete_success}</SuccessAlert>}
      <p className="text-italic line-height-sans-5">
        <span className="margin-right-1">
          <RoleNameTag name={query_data.role.name} level={query_data.role.level} />
        </span>
        You are currently viewing{' '}
        <span className="text-bold word-break-all">{query_data.email}</span>
      </p>
      {query_data.has_login_gov && <LinkedLoginGovInfo />}
      <FormLayout handleSubmit={handleSubmit}>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="first_name"
              input={formData.first_name || ''}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_required
              is_validate_length
              is_disabled={!privileges.can_edit_users}
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="last_name"
              input={formData.last_name || ''}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_required
              is_validate_length
              is_disabled={!privileges.can_edit_users}
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.job_title}
              id_name="job_title"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              is_disabled={!privileges.can_edit_users}
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.org}
              id_name="org"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              is_disabled={!privileges.can_edit_users}
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormPhoneInputLazy
              phone={formData.phone}
              handleChange={handlePhoneChange}
              setValidationMessages={setValidationMessages}
              is_disabled={!privileges.can_edit_users}
            />
          </div>
          {formData.role_fk && privileges.can_edit_users ? (
            <RoleDropdown
              role_fk={formData.role_fk}
              handleChange={handleDropdownChange}
              roles={roles_data}
            />
          ) : (
            <section className="mobile-lg:grid-col-6">
              <Label htmlFor="readonly-role" className="maxw-none">
                <span className="text-primary text-semibold">
                  {metadata_client_account.role.title}
                </span>
              </Label>
              <TextInput
                id="readonly-role"
                name="readonly-role"
                type="text"
                disabled
                defaultValue={capitalizeFirstLetter(query_data.role.name)}
              />
            </section>
          )}
        </Grid>
        {privileges.can_edit_users && (
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6 margin-top-2">
              <FormCheckbox
                id_name="is_removed"
                is_checked={formData.is_removed}
                handleChange={handleDeleteCheckbox}
                show_hint
                is_disabled={!privileges.can_edit_users}
                form_type="account"
                use_danger_styles
                use_full_width
              />
            </div>
            <ConfirmAccountDeletionModal
              isModalOpen={isDeleteModalOpen}
              handleDeleteConfirmation={handleDeleteConfirmation}
            />
            <div className="mobile-lg:grid-col-6 margin-top-2">
              <FormCheckbox
                id_name="is_disabled"
                is_checked={formData.is_disabled}
                handleChange={handleCheckbox}
                show_hint
                is_disabled={!privileges.can_edit_users}
                form_type="account"
                use_danger_styles
                use_full_width
              />
            </div>
          </Grid>
        )}
        <section className="padding-2 margin-top-4 bg-internal">
          <div className="margin-top-neg-3">
            <FormTextArea
              input={formData.admin_notes}
              id_name="admin_notes"
              handleChange={handleTextAreaChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              is_disabled={!privileges.can_edit_users}
              form_type="account"
            />
          </div>
        </section>
        {privileges.can_edit_users && (
          <BottomContainer>
            <div className="grid-container-tablet padding-x-0 grid-row flex-justify-end">
              <FormSubmit
                validationMessages={validationMessages}
                is_disabled={is_submit_disabled}
                ref={submit_btn_ref}
              >
                Save changes
              </FormSubmit>
            </div>
          </BottomContainer>
        )}
        <DiscardChanges
          isFormDirty={isFormDirty}
          disable_nav_blocker={is_discard_disabled}
          visibility="none"
        />
      </FormLayout>
      <section className="margin-top-4">
        <AdditionalInformation query_data={query_data} info_type="account" />
      </section>
      {privileges.can_view_piv_data && (
        <AccountPivDetails
          piv_certificates={query_data.piv_certificates}
          user_id={query_data.pk}
          setIsPivDeleteSuccess={setIsPivDeleteSuccess}
          handleSuccessfulPivDelete={handleSuccessfulPivDelete}
        />
      )}
    </section>
  );
}

import {Icon, Tag} from '@trussworks/react-uswds';
import styles from './CustomRegQuestions.module.scss';
import Accordion, {AccordionItem} from '../../Accordion/Accordion';
import {
  addOrRemoveValidationMessage,
  crq_type_message_dict,
  getUniqueCrqId,
} from '../../../lib/utils';
import {SetValidationMessages} from '../../../types/form-types';
import {Tooltip} from 'react-tooltip';
import CustomRegQuestion from '../CustomRegQuestion/CustomRegQuestion';
import SectionTitle from '../../FormComponents/SectionTitle/SectionTitle';
import {MtgFormCRQuestion} from '../../../types/meeting-types';
import DeleteCRModal from '../DeleteCRModal/DeleteCRModal';
import {useState} from 'react';
import {useEffect} from 'react';
import {v4 as uuidV4} from 'uuid';

export type SetCrqs = React.Dispatch<React.SetStateAction<MtgFormCRQuestion[]>>;

interface Props {
  crqs: MtgFormCRQuestion[];
  setCrqs: SetCrqs;
  setValidationMessages: SetValidationMessages;
  is_edit_mtg?: boolean;
}

export interface DeleteCRModal {
  is_open: boolean;
  crq_idx: number | null;
  cro_idx?: number | null; // If cro_idx is passed, the modal will be used for CRO deletion
}

export type InvalidCrqIdsBySource = {
  from_crq_validation: string[];
  from_cro_validation: string[];
};

export type SetDeleteModal = React.Dispatch<React.SetStateAction<DeleteCRModal>>;
export type SetInvalidCrqIdsBySource = React.Dispatch<React.SetStateAction<InvalidCrqIdsBySource>>;
export type SetInvalidCroIds = React.Dispatch<React.SetStateAction<string[]>>;

const initial_modal: DeleteCRModal = {
  is_open: false,
  crq_idx: null,
  cro_idx: null,
};

export default function CustomRegQuestions({
  crqs,
  setCrqs,
  setValidationMessages,
  is_edit_mtg,
}: Props) {
  const [deleteCRModal, setDeleteCRModal] = useState<DeleteCRModal>(initial_modal);
  const [invalidCrqIdsBySource, setInvalidCrqIdsBySource] = useState<InvalidCrqIdsBySource>({
    from_crq_validation: [],
    from_cro_validation: [],
  });
  const [invalidCroIds, setInvalidCroIds] = useState<string[]>([]);
  const invalidCrqIds = [...new Set(Object.values(invalidCrqIdsBySource).flat())];

  const crqs_qty = crqs.filter((crq) => !crq.is_removed).length;
  const has_maxed_crqs = crqs_qty >= 15;
  const has_exceeded_max_crqs = crqs_qty > 15;
  const maxed_crqs_tooltip_msg =
    'Only 15 additional registration questions may be added to each meeting';

  useEffect(() => {
    // Remove CRQs that have been deleted from the form
    const all_crq_local_ids = crqs.map((crq) => crq.local_id);
    setInvalidCrqIdsBySource((source_dict) => {
      const new_source_dict = {...source_dict};
      for (const key of Object.keys(new_source_dict) as Array<keyof typeof new_source_dict>) {
        const new_ids = new_source_dict[key].filter((id) => all_crq_local_ids.includes(id));
        new_source_dict[key] = new_ids;
      }
      return new_source_dict;
    });

    // Remove CROs that have been deleted from the form
    const all_cro_local_ids = crqs.flatMap((crq) =>
      (crq.cr_options ?? []).map((cro) => cro.local_id)
    );
    setInvalidCroIds((ids) => ids.filter((id) => all_cro_local_ids.includes(id)));
  }, [crqs]);

  useEffect(() => {
    addOrRemoveValidationMessage({
      is_condition_met: has_exceeded_max_crqs,
      err_message:
        'Only 15 additional registration questions may be added to each meeting. Please delete excess questions',
      setValidationMessages,
    });
  }, [has_exceeded_max_crqs, setValidationMessages]);

  const handleAddQuestion = (type: 'textarea' | 'radio') => {
    if (has_maxed_crqs) return;

    let new_question: MtgFormCRQuestion;
    const highest_existing_order = crqs.reduce(
      (max, curr_crq) => Math.max(max, curr_crq.order),
      -1
    );
    if (type == 'textarea') {
      new_question = {
        title: '',
        order: highest_existing_order + 1,
        is_required: false,
        type: 'textarea',
        cr_options: null,
        local_id: uuidV4(),
      };
    } else {
      new_question = {
        title: '',
        order: highest_existing_order + 1,
        is_required: false,
        type: 'radio',
        cr_options: [],
        local_id: uuidV4(),
      };
    }
    setCrqs([...crqs, new_question]);
  };

  function handleDeleteQuestionModal() {
    const idx = deleteCRModal.crq_idx;
    if (idx != null) {
      setCrqs((crqs) => {
        const new_questions = [...crqs];
        new_questions[idx].is_removed = true;
        return new_questions;
      });
    }
  }

  function handleDeleteOptionModal() {
    const crq_idx = deleteCRModal.crq_idx;
    const cro_idx = deleteCRModal.cro_idx;
    if (crq_idx != null && cro_idx != null) {
      setCrqs((crqs) => {
        const new_questions = [...crqs];
        const curr_crq = new_questions[crq_idx];
        const curr_cro = curr_crq.cr_options?.[cro_idx];
        if (!curr_cro) throw new Error(`CRO[${cro_idx}] in CRQ[${crq_idx}] is missing`);
        curr_cro.is_removed = true;
        return new_questions;
      });
    }
  }

  const accordion_items: AccordionItem<MtgFormCRQuestion>[] = crqs.map((crq, idx) => {
    const cro_local_ids = crq.cr_options?.map((cro) => cro.local_id) ?? [];

    return {
      id: getUniqueCrqId(crq),
      original_item: crq,
      title: (
        <>
          {idx + 1}. {crq.title || crq_type_message_dict[crq.type].untitled_title}
          {is_edit_mtg && !crq.pk && <Tag className="bg-violet margin-left-1">New</Tag>}
          {is_edit_mtg && crq.is_removed && (
            <Tag className="bg-secondary-dark margin-left-1">Marked for deletion</Tag>
          )}
        </>
      ),
      content: (
        <CustomRegQuestion
          crq={crq}
          crq_idx={idx}
          num_crqs={crqs.length}
          setCrqs={setCrqs}
          setValidationMessages={setValidationMessages}
          setDeleteCRModal={setDeleteCRModal}
          setInvalidCrqIdsBySource={setInvalidCrqIdsBySource}
          setInvalidCroIds={setInvalidCroIds}
          is_edit_mtg={is_edit_mtg}
        />
      ),
      is_initially_expanded: !crq.pk, // CRQ renders expanded if it isn't an existing CRQ
      is_invalid:
        invalidCrqIds.includes(crq.local_id) ||
        cro_local_ids.some((cro_local_id) => invalidCroIds.includes(cro_local_id)),
      is_disabled: is_edit_mtg && crq.is_removed,
    };
  });

  return (
    <section className="margin-top-3">
      <SectionTitle>Additional registration questions</SectionTitle>
      {!!crqs.length && <Accordion accordion_items={accordion_items} />}
      {deleteCRModal.cro_idx != null ? (
        // Modal for CRO deletion
        <DeleteCRModal
          isModalOpen={deleteCRModal.is_open}
          closeModal={() => setDeleteCRModal(initial_modal)}
          modal_heading={`Delete "${
            // The title technically could never be empty since cr_options has to exist for radio questions
            (deleteCRModal.crq_idx != null &&
              crqs[deleteCRModal.crq_idx].cr_options?.[deleteCRModal.cro_idx].title) ||
            ''
          }"`}
          handleDelete={handleDeleteOptionModal}
          is_btn_enabled={
            !invalidCroIds.includes(
              (deleteCRModal.crq_idx != null &&
                crqs[deleteCRModal.crq_idx].cr_options?.[deleteCRModal.cro_idx].local_id) ||
                ''
            )
          }
          is_cro
        >
          <p>
            Are you sure you want to delete this option?{' '}
            <span className="text-secondary">
              {
                'If registrants have already responded with this option, their answer will be lost and it will be as if the registrant has not answered this question. This option will no longer appear on registrant forms or registrant lists.'
              }
            </span>
          </p>
          <p>
            Clicking the &quot;Mark for deletion&quot; button below will cause this option to be
            deleted when the &quot;Save changes&quot; button is clicked and the meeting is saved. If
            &quot;Save changes&quot; is not clicked, this option will remain in place.
          </p>
        </DeleteCRModal>
      ) : (
        // Modal for CRQ deletion
        <DeleteCRModal
          isModalOpen={deleteCRModal.is_open}
          closeModal={() => setDeleteCRModal(initial_modal)}
          modal_heading={`Delete "${
            (deleteCRModal.crq_idx != null &&
              (crqs[deleteCRModal.crq_idx].title ||
                crq_type_message_dict[crqs[deleteCRModal.crq_idx].type].untitled_title)) ||
            ''
          }"`}
          handleDelete={handleDeleteQuestionModal}
          is_btn_enabled={
            !invalidCrqIds.includes(
              (deleteCRModal.crq_idx != null && crqs[deleteCRModal.crq_idx].local_id) || ''
            )
          }
        >
          <p>
            Are you sure you want to delete this question?{' '}
            <span className="text-secondary">
              {
                'If registrants have already responded to this question, their responses will be lost and this question will no longer appear on registration forms or registrant lists.'
              }
            </span>
          </p>
          <p>
            Clicking the &quot;Mark for deletion&quot; button below will cause this question to be
            deleted when the &quot;Save changes&quot; button is clicked and the meeting is saved. If
            &quot;Save changes&quot; is not clicked, this question will remain in place.
          </p>
        </DeleteCRModal>
      )}
      <section className="display-flex flex-wrap">
        <div className="width-full mobile-lg:width-auto">
          <button
            id="add-textarea-btn"
            type="button"
            className={`${styles.add_crq_btn} usa-button usa-button--outline margin-right-2`}
            onClick={() => handleAddQuestion('textarea')}
            aria-disabled={has_maxed_crqs}
          >
            <Icon.AddCircleOutline className="margin-right-1 flex-shrink-0" role="presentation" />
            Add a text field question
          </button>
          {has_maxed_crqs && (
            <Tooltip anchorSelect="#add-textarea-btn" place="bottom">
              {maxed_crqs_tooltip_msg}
            </Tooltip>
          )}
        </div>
        <div className="width-full mobile-lg:width-auto">
          <button
            id="add-radio-btn"
            type="button"
            className={`${styles.add_crq_btn} usa-button usa-button--outline`}
            onClick={() => handleAddQuestion('radio')}
            aria-disabled={has_maxed_crqs}
          >
            <Icon.AddCircleOutline className="margin-right-1 flex-shrink-0" role="presentation" />
            Add a radio button question
          </button>
          {has_maxed_crqs && (
            <Tooltip anchorSelect="#add-radio-btn" place="bottom">
              {maxed_crqs_tooltip_msg}
            </Tooltip>
          )}
        </div>
      </section>
    </section>
  );
}

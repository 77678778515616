import {useCallback, useEffect, useState} from 'react';
import {
  MeetingsPostPutData,
  MtgPostPutResFile,
} from '../../../../common/types/meetings-post-put-res';
import {
  FilesInputDict,
  MtgFileUpload,
  MtgFormFile,
  ViewMeetingAlertState,
} from '../../../types/meeting-types';
import UploadFilesModal from '../UploadFilesModal/UploadFilesModal';
import {useNavigate} from 'react-router-dom';
import {useMutationUploadMeetingFile} from '../../../services/meeting-queries';

function prepareFileUploads(
  form_files: MtgFormFile[],
  res_files: MtgPostPutResFile[]
): MtgFileUpload[] {
  return form_files
    .filter((file) => file.file_name && !file.is_removed)
    .sort((file) => file.order)
    .map<MtgFileUpload | undefined>((file) => {
      const mtg_file_id = res_files.find((res_file) => res_file.upload_id == file.upload_id)?.pk;
      if (!mtg_file_id) {
        // Shouldn't get here. This would indicate the server responded with a different set of files than those in the
        // current form state.
        if (g_is_dev) console.error(`mtg_file_id not available for upload_id=${file.upload_id}`);
        return;
      }

      return {
        status: 'in_progress',
        mtg_file_id,
        title: file.title,
        file_name: file.file_name ?? '',
        upload_id: file.upload_id,
      };
    })
    .filter((status): status is MtgFileUpload => !!status);
}

type UploadFilesModal = {
  is_open: boolean;
  mtg_id: string;
  mtg_title: string;
  uploads: MtgFileUpload[];
};

type Props = {
  form_files: MtgFormFile[];
  mtg_data: MeetingsPostPutData | null;
  file_inputs_dict: FilesInputDict;
} & (
  | {
      is_edit_mtg: true;
      reloadMeeting: () => void;
    }
  | {
      is_edit_mtg?: never;
      reloadMeeting?: never;
    }
);

export default function UploadFiles({
  form_files,
  mtg_data,
  file_inputs_dict,
  is_edit_mtg,
  reloadMeeting,
}: Props) {
  const navigate = useNavigate();
  const [uploadFilesModal, setUploadFilesModal] = useState<UploadFilesModal>({
    is_open: false,
    mtg_id: '',
    mtg_title: '',
    uploads: [],
  });

  // The result of a react-query mutation hook is not stable to use when detecting changes for useCallback or useEffect,
  // but the mutation function is.
  const uploadFileMutation = useMutationUploadMeetingFile();
  const {mutateAsync} = uploadFileMutation;

  const navigateToMeeting = useCallback(
    (mtg_id: string) => {
      navigate(`/meetings/${mtg_id}`, {
        state: (is_edit_mtg ? {is_edit_mtg} : {is_new_mtg: true}) as ViewMeetingAlertState,
      });
    },
    [navigate, is_edit_mtg]
  );

  const setUploadStatus = (upload_id: string, status: MtgFileUpload['status']) => {
    setUploadFilesModal((state) => {
      const upload_idx = state.uploads.findIndex((upload) => upload.upload_id == upload_id);
      if (upload_idx < 0) {
        // Shouldn't get here. This would indicate uploadFilesModal is dropping files during upload.
        throw new Error(`File upload state not available for upload_id=${upload_id}`);
      }
      const new_uploads = [...state.uploads];
      new_uploads[upload_idx] = {...new_uploads[upload_idx], status};
      return {...state, uploads: new_uploads};
    });
  };

  const processUploads = useCallback(
    async (mtg_id: string, files: MtgFileUpload[]) => {
      // Process sequentially
      for (const file of files) {
        const {mtg_file_id, upload_id} = file;
        let input_file = file_inputs_dict[upload_id]?.files?.[0];
        try {
          if (!input_file) {
            // Shouldn't get here. This would indicate file_inputs_ref is not maintained correctly.
            throw new Error('<input> file not available');
          }
          // Use sanitized file name
          if (input_file.name != file.file_name) {
            input_file = new File([input_file], file.file_name, {
              type: input_file.type,
              lastModified: input_file.lastModified,
            });
          }
          await mutateAsync({mtg_id, mtg_file_id, file: input_file});
          setUploadStatus(file.upload_id, 'success');
        } catch (err) {
          if (g_is_dev)
            console.error(`Failed to upload file for mtg_file_id=${mtg_file_id}\n`, err);
          setUploadStatus(file.upload_id, 'error');
        }
      }
    },
    [file_inputs_dict, mutateAsync]
  );

  useEffect(
    () => {
      if (!mtg_data) return;

      const file_uploads = prepareFileUploads(form_files, mtg_data.files);
      if (file_uploads.length) {
        setUploadFilesModal({
          is_open: true,
          mtg_id: mtg_data.pk,
          mtg_title: mtg_data.title,
          uploads: file_uploads,
        });

        // Do not await. Let the effect conclude.
        processUploads(mtg_data.pk, file_uploads).catch((err) => {
          if (g_is_dev) console.error('Failed to complete uploads\n', err);
        });
      } else {
        navigateToMeeting(mtg_data.pk);
      }
    },
    // If possible, avoid letting this effect depend on uploadFilesModal. Otherwise, extra care needs to be put into
    // making sure uploads are triggered only once.
    [mtg_data, form_files, processUploads, navigateToMeeting]
  );

  useEffect(() => {
    if (!mtg_data) return;

    // If every upload succeeds, navigate automatically. Otherwise, leave the modal up and let the user click a button
    // to (re)load the edit meeting form.
    if (
      uploadFilesModal.uploads.length > 0 &&
      uploadFilesModal.uploads.every((upload) => upload.status == 'success')
    ) {
      setUploadFilesModal((state) => ({...state, is_open: false}));
      navigateToMeeting(mtg_data.pk);
    }
  }, [mtg_data, uploadFilesModal, navigateToMeeting]);

  return (
    <UploadFilesModal
      isModalOpen={uploadFilesModal.is_open}
      handleFailure={() => {
        setUploadFilesModal((state) => ({...state, is_open: false}));
        if (is_edit_mtg) {
          reloadMeeting();
        } else {
          navigate(`/meetings/${uploadFilesModal.mtg_id}/edit`);
        }
      }}
      meeting_title={uploadFilesModal.mtg_title}
      uploads={uploadFilesModal.uploads}
    />
  );
}

import {Accordion} from '@trussworks/react-uswds';
import {MtgBroadcastPostData} from '../../../../common/types/mtg-broadcast-post-res';
import EmailPills from '../../FormComponents/MeetingFormComponents/EmailsInput/EmailPills/EmailPills';

interface Props {
  emails: MtgBroadcastPostData['aggregate_emails'];
}

export default function MtgBroadcastSuccessAccordion({emails}: Props) {
  const accordion_items: React.ComponentProps<typeof Accordion>['items'] = [
    {
      title: 'Click to view recipient list',
      content: <EmailPills emails={emails} />,
      expanded: false,
      id: 'recipient-email-list-accordion',
      headingLevel: 'h3',
      className: 'font-sans-xs',
    },
  ];
  return (
    <Accordion items={accordion_items} className="mtg-success-accordion margin-top-1" bordered />
  );
}

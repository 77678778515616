import {useState} from 'react';
import {Form, Fieldset, TextInput, Label, Button, ErrorMessage} from '@trussworks/react-uswds';
import {getLoginGovLoginUrl, isEmailValid} from '../../../lib/utils';
import {useMutationSubmitEmail} from '../../../services/auth-queries';
import {ModalSwitchTypes, UserNavTypes} from '../../../types/auth-types';
import logingovlogo from '../../../images/login-gov-logo.svg';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';

interface Props {
  setCurrentModal: React.Dispatch<React.SetStateAction<ModalSwitchTypes>>;
  userNav: UserNavTypes;
  setRecipientEmail: React.Dispatch<React.SetStateAction<string>>;
  redirect?: string;
}

interface ErrorStateInterface {
  errorMessage: string;
  validationStatus: 'error' | undefined;
}

export default function Auth({setCurrentModal, userNav, setRecipientEmail, redirect}: Props) {
  const [userEmail, setUserEmail] = useState('');
  const [errorState, setErrorState] = useState<ErrorStateInterface>({
    errorMessage: '',
    validationStatus: undefined,
  });

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.trim();
    setUserEmail(value);
    if (isEmailValid(value)) setErrorState({errorMessage: '', validationStatus: undefined});
  }

  const submitEmail = useMutationSubmitEmail();
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isEmailValid(userEmail)) {
      submitEmail.mutate(
        {email: userEmail, redirect},
        {
          onSuccess: ({email}) => {
            setRecipientEmail(email);
            setCurrentModal('email-sent');
          },
          onError: () => {
            setCurrentModal('error');
          },
        }
      );
    } else {
      setErrorState({
        errorMessage: metadata_client_account.email.validation_message,
        validationStatus: 'error',
      });
    }
  }

  return (
    <>
      <h2 className="margin-top-0">
        {userNav == 'signup'
          ? 'Create an account'
          : userNav == 'piv'
            ? 'Sign in (DOT personnel)'
            : 'Select a sign in method'}
      </h2>
      {userNav == 'piv' ? (
        <>
          <h3>How to associate your PIV card:</h3>
          <ol className="usa-list">
            <li>Sign in with your email address below.</li>
            <li>Follow the link you receive in your email to sign in.</li>
            <li>Once you are signed in, click your initials in the top right.</li>
            <li>Select &quot;Associate PIV card&quot; from the dropdown and follow the prompts.</li>
          </ol>
        </>
      ) : (
        <a
          href={getLoginGovLoginUrl(redirect)}
          className="usa-button usa-button--outline width-full margin-bottom-105 box-shadow-3 word-break-word"
        >
          Create an account with <img src={logingovlogo} className="height-105" alt="Login.gov" />
        </a>
      )}

      <Form onSubmit={handleSubmit} className="maxw-full">
        <Fieldset legend="Select a Sign In Method" legendStyle="srOnly">
          {userNav != 'piv' && (
            <h2 className="hrheader">
              {userNav == 'signup' ? 'Create an account without ' : 'Sign in without '}Login.gov
            </h2>
          )}
          <Label htmlFor="email">Email address *</Label>
          {errorState.errorMessage && <ErrorMessage>{errorState.errorMessage}</ErrorMessage>}
          <TextInput
            id="email"
            name="email"
            type="text"
            placeholder="email@domain.com"
            autoCapitalize="off"
            autoCorrect="off"
            className="radius-sm"
            onChange={handleOnChange}
            validationStatus={errorState.validationStatus}
            disabled={submitEmail.isPending}
          />
          <Button
            type="submit"
            className="usa-button usa-button--outline width-full margin-bottom-105 box-shadow-3 margin-top-0"
            disabled={submitEmail.isPending}
          >
            {submitEmail.isPending ? (
              <TextLoading />
            ) : userNav == 'signup' ? (
              'Create an account'
            ) : (
              'Sign in with email'
            )}
          </Button>
        </Fieldset>
      </Form>
      <div className="text-right">
        <Button
          type="button"
          onClick={() => setCurrentModal('auth-index')}
          className="usa-button--unstyled width-auto"
        >
          Go back
        </Button>
      </div>
    </>
  );
}

import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useQueryCheckToken, useMutationConfirmToken} from '../../services/auth-queries';
import {getQueryErrCode} from '../../lib/utils';
import LoginError from '../LoginError/LoginError';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {Button} from '@trussworks/react-uswds';
import {useCallback, useEffect, useState} from 'react';
import {app_name} from '../../../common/lib/shared-vars';
import TextLoading from '../../components/LoadingComponents/TextLoading/TextLoading';

export default function Confirmation() {
  usePageTitle('Confirmation');

  const {token_id} = useParams();
  const sl_token = token_id ?? '';

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let redirect = searchParams.get('redirect') || '/';
  if (!/^\//.test(redirect)) {
    redirect = '/';
  }

  const [isContinuePending, setIsContinuePending] = useState(false);
  const query = useQueryCheckToken(sl_token, {is_enabled: !isContinuePending});
  const mutation = useMutationConfirmToken();

  const performNavigation = useCallback(
    (is_user_verified: boolean) => {
      if (!is_user_verified) {
        let toUrl = '/confirm-account';
        if (redirect != '/') {
          toUrl += `?redirect=${encodeURIComponent(redirect)}`;
        }
        navigate(toUrl);
      } else {
        navigate(redirect);
      }
    },
    [navigate, redirect]
  );

  useEffect(() => {
    if (query.data?.is_session_reuse) {
      setIsContinuePending(true);
      performNavigation(query.data.is_user_verified);
    }
  }, [performNavigation, query.data?.is_user_verified, query.data?.is_session_reuse]);

  const handleClick = () => {
    setIsContinuePending(true);
    mutation.mutate(sl_token, {
      onSuccess: (data) => performNavigation(data.is_user_verified),
    });
  };

  if (query.isPending) {
    return <PageLoading />;
  }
  if (query.isError || mutation.isError) {
    const err = mutation.isError ? mutation.error : query.error;
    return <LoginError is_disabled_user={getQueryErrCode(err) == 'USER_DISABLED'} />;
  }

  return (
    <div>
      <h2>Welcome to {app_name}</h2>
      <p>Please click &quot;Continue&quot; to finish signing in.</p>
      <Button type="button" onClick={handleClick} disabled={isContinuePending}>
        {isContinuePending ? <TextLoading /> : 'Continue'}
      </Button>
    </div>
  );
}

import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';
import {useMutationDeletePiv} from '../../../services/account-queries';
import {usePrivilegesContext} from '../../../lib/PrivilegesContext';
import {DeletePivModalState} from '../../../types/piv-types';
import {piv_delete_err} from '../../../lib/utils';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';

type Props = {
  closeModal: () => void;
  deletePivModal: DeletePivModalState;
  handleSuccessfulPivDelete: () => void;
};

export default function DeletePivModal({
  closeModal,
  deletePivModal,
  handleSuccessfulPivDelete,
}: Props) {
  const pk = deletePivModal.pk;
  const user_id = deletePivModal.user_id || '';
  const serial = deletePivModal.serial;
  const mutation = useMutationDeletePiv(user_id);
  const {pk: current_user_id} = usePrivilegesContext();

  function handleDeletePiv(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (pk != null)
      mutation
        .mutateAsync(pk)
        .then(() => {
          handleSuccessfulPivDelete();
          closeModal();
        })
        .catch(() => {
          // Do nothing, err handled in `mutation.isError` below
        });
  }

  function handleClose() {
    if (deletePivModal.setIsSelectedPiv) deletePivModal.setIsSelectedPiv(false);
    closeModal();
  }

  return (
    <>
      <GenericModalLayout
        isOpen={deletePivModal.isModalOpen}
        closeModal={handleClose}
        modal_heading="Delete PIV"
      >
        <>
          <div className="usa-prose">
            Are you sure you want to delete the PIV certificate with serial number: {serial}?
            <br />
            {user_id == current_user_id ? 'You ' : 'The user '}will no longer be able to log in
            using the associated PIV card.
          </div>
          {mutation.isError && <ErrorAlert>{piv_delete_err}</ErrorAlert>}
        </>
        <ModalFooter>
          <ButtonGroup
            type="default"
            className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
          >
            <Button
              type="button"
              className="usa-button--secondary"
              disabled={mutation.isPending}
              onClick={handleDeletePiv}
            >
              {mutation.isPending ? <TextLoading /> : 'Delete PIV certificate'}
            </Button>
            <Button type="button" onClick={handleClose} disabled={mutation.isPending} unstyled>
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </GenericModalLayout>
    </>
  );
}

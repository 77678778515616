import {Button} from '@trussworks/react-uswds';
import {ModalSwitchTypes} from '../../../types/auth-types';
import {useMutationSubmitEmail} from '../../../services/auth-queries';
import SupportEmailLink from '../../SupportEmailLink/SupportEmailLink';
import {useState} from 'react';

// Initial timeout is 10 secs
const send_email_timeout = 10000;

interface Props {
  setCurrentModal: React.Dispatch<React.SetStateAction<ModalSwitchTypes>>;
  recipientEmail: string;
}

export default function EmailSent({setCurrentModal, recipientEmail}: Props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailRetries, setEmailRetries] = useState(0);

  const submitEmail = useMutationSubmitEmail();

  function handleClick() {
    setIsDisabled(true);
    // User can re-request 5 times, on 6th re-request will throw error (first re-request is 0 retries)
    if (emailRetries >= 5) {
      setCurrentModal('error');
      throw new Error('Re-request limit reached. Please contact support.');
    }
    setTimeout(
      () => {
        setIsDisabled(false);
        setEmailRetries(emailRetries + 1);
      },
      send_email_timeout * Math.pow(2, emailRetries)
    );
    submitEmail.mutate(
      {email: recipientEmail},
      {
        onError: () => {
          setCurrentModal('error');
        },
      }
    );
  }

  return (
    <>
      <h2 className="margin-top-0">Email sent</h2>
      <p>
        An email was sent to: <strong>{recipientEmail}</strong>. Please review the instructions to
        complete signing in to the Meetings application.
      </p>
      <p>
        If you have not received the email, check your spam folder and try searching for &quot;Sign
        in to PHMSA Public Meetings&quot;. If you do not receive an email, please click the button
        below to re-send.
      </p>
      <button
        type="button"
        onClick={handleClick}
        className="usa-button usa-button--outline width-full box-shadow-3 margin-top-0 word-break-word"
        disabled={isDisabled}
      >
        Resend email
      </button>
      <div className="text-center mobile-lg:text-left margin-top-1">
        <Button
          type="button"
          onClick={() => setCurrentModal('auth')}
          className="usa-button--unstyled width-auto"
        >
          Use a different email address
        </Button>
      </div>
      <p className="text-center mobile-lg:text-left font-sans-3xs margin-top-1 margin-bottom-0">
        Trouble logging in? Please reach out to <SupportEmailLink />.
      </p>
    </>
  );
}

import {useState, useMemo, useEffect} from 'react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  VisibilityState,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import {Breadcrumb, BreadcrumbBar, Tag, FormGroup, Label, TextInput} from '@trussworks/react-uswds';
import {useQueryAccountsList} from '../../services/account-queries';
import {AccountsGetDataAccount, AccountsGetData} from '../../../common/types/accounts-get-res';
import RoleNameTag from '../../components/AccountComponents/RoleNameTag/RoleNameTag';
import {Link, useLocation} from 'react-router-dom';
import metadata_client_account from '../../lib/metadata-client-account.json';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import TableUI from '../../components/TableUI/TableUI';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {ManageAccountsAlertState} from '../../types/meeting-types';
import {acc_delete_success, fromUTCtoET} from '../../lib/utils';
import CheckboxWithTooltip from '../../components/FormComponents/CheckboxWithTooltip/CheckboxWithTooltip';
import LongFieldWithTooltip from '../../components/LongFieldWithTooltip/LongFieldWithTooltip';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

export default function ManageAccounts() {
  usePageTitle('Manage accounts');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState({
    show_disabled: false,
    show_unverified: false,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    show_unverified: false,
    show_disabled: false,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const query = useQueryAccountsList(filters);
  const privileges = usePrivilegesContext();
  const location = useLocation();
  const state = location.state as ManageAccountsAlertState | null;

  useEffect(() => {
    // Prevent alert from persisting after refresh
    if (state) window.history.replaceState(null, document.title);
  }, [state]);

  const columns = useMemo(() => {
    const primary_cols: ColumnDef<AccountsGetDataAccount>[] = [
      {
        accessorFn: (row) => row.first_name ?? '',
        header: metadata_client_account.first_name.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.last_name ?? '',
        header: metadata_client_account.last_name.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'email',
        header: metadata_client_account.email.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.role.name,
        id: 'Role',
        cell: (info) => (
          <RoleNameTag name={info.getValue<string>()} level={info.row.original.role.level} />
        ),
        header: metadata_client_account.role.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => (row.is_verified ? 'Verified' : 'Unverified'),
        id: 'show_unverified',
        cell: (info) =>
          info.row.original.is_verified ? (
            <Tag className="bg-success-dark text-semibold font-sans-2xs">Verified</Tag>
          ) : (
            <Tag className="bg-error-dark text-semibold font-sans-2xs">Unverified</Tag>
          ),
        header: metadata_client_account.is_verified.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'is_disabled',
        accessorFn: (row) => (row.is_disabled ? 'Disabled' : 'Enabled'),
        id: 'show_disabled',
        cell: (info) =>
          info.row.original.is_disabled ? (
            <Tag className="bg-base text-semibold font-sans-2xs">Disabled</Tag>
          ) : (
            <Tag className="bg-success-dark text-semibold font-sans-2xs">Enabled</Tag>
          ),
        header: metadata_client_account.is_disabled.title,
        sortingFn: 'text',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.login_last_datetime ?? '',
        cell: (info) => {
          const last_login = info.getValue<string>();
          return last_login ? fromUTCtoET(last_login, {use_long_date: true}) : 'N/A';
        },
        header: 'Last login',
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'pk',
        cell: (info) => {
          const my_pk = privileges.pk;
          const user_pk = info.getValue<string>();
          return (
            <Link
              to={user_pk == my_pk ? '/my-account' : `/manage-accounts/account/${user_pk}`}
              className="usa-link text-no-wrap"
              data-user_pk={user_pk == my_pk ? user_pk : undefined}
            >
              {user_pk == my_pk ? 'My account' : 'Details'}
            </Link>
          );
        },
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ];

    if (privileges.can_view_login_gov_data) {
      // Inserted as the second-to-last column, after 'Last Login' and before the Account Details
      primary_cols.splice(7, 0, {
        accessorFn: (row) => row.login_gov_emails?.join(', ') ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.login_gov_emails.title,
        enableSorting: false,
      });
    }
    return primary_cols;
  }, [privileges.can_view_login_gov_data, privileges.pk]);

  const table = useReactTable({
    data: query.data as AccountsGetData,
    columns,
    state: {sorting, columnVisibility, globalFilter},
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnPinning: {
        right: ['pk'],
      },
    },
  });

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const event_name = event.currentTarget.name;
    const current_filter = filters[event_name as keyof typeof filters];
    setFilters({
      ...filters,
      [event.currentTarget.name]: !current_filter,
    });
    table.getColumn(event_name)?.toggleVisibility(!current_filter);
  }

  // This shouldn't render since we're using err boundary in the query
  if (query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Manage accounts</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {state?.is_acc_deleted && <SuccessAlert>{acc_delete_success}</SuccessAlert>}
      {privileges.can_create_users && (
        <Link
          to="/manage-accounts/create-account"
          className="usa-button usa-button--secondary margin-bottom-4"
        >
          Create new account
        </Link>
      )}
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word">
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            Search names and emails
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
        <section className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1">
          <span className="text-italic margin-right-0 mobile-lg:margin-right-5">Filters</span>
          <CheckboxWithTooltip
            id_name="show_unverified"
            is_checked={filters.show_unverified}
            label="Show unverified accounts"
            tooltip_text="When this box is checked, unverified accounts will also appear in the list below"
            handleCheckbox={handleCheckboxChange}
          />
          <CheckboxWithTooltip
            id_name="show_disabled"
            is_checked={filters.show_disabled}
            label="Show disabled accounts"
            tooltip_text="When this box is checked, disabled accounts will also appear in the list below"
            handleCheckbox={handleCheckboxChange}
          />
        </section>
      </section>
      {query.isPending ? (
        <PageLoading message="Loading accounts" />
      ) : (
        <TableUI
          table={table}
          clickedIndex={clickedIndex}
          setClickedIndex={setClickedIndex}
          variant="manage_accounts_table"
        />
      )}
    </>
  );
}

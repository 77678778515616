import {Icon} from '@trussworks/react-uswds';
import {MouseEvent} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './AccountBtn.module.scss';
import {
  useMutationAssociatePiv,
  useQueryMyAccount,
  useQueryPrivileges,
} from '../../../../services/account-queries';
import {useMutationLogout} from '../../../../services/auth-queries';
import {MeetingsListAlertState} from '../../../../types/meeting-types';
import {canUserAssociatePiv} from '../../../../../common/utils/auth-utils';
import {scrollToTop} from '../../../../lib/utils';
import {LogoutConfirmationState, PivAssociationTypes} from '../../../../types/auth-types';
import {HandleSubMenu, IsSetMobileNavExpanded} from '../Navbar';

interface Props {
  name: string;
  isSubMenuOpen: boolean;
  handleSubMenu: HandleSubMenu;
  setIsMobileNavExpanded: IsSetMobileNavExpanded;
  is_location_confirm_account: boolean;
  setPivAssociationState: React.Dispatch<React.SetStateAction<PivAssociationTypes>>;
}

export default function AccountBtn({
  name,
  isSubMenuOpen,
  handleSubMenu,
  setIsMobileNavExpanded,
  is_location_confirm_account,
  setPivAssociationState,
}: Props) {
  const navigate = useNavigate();
  const logout = useMutationLogout();
  const account_query = useQueryMyAccount();
  const privileges_query = useQueryPrivileges({});
  const pivMutation = useMutationAssociatePiv(privileges_query.data?.pk);

  // If the user is unverified & at /confirm-account, only display signout
  const display_all = !is_location_confirm_account || privileges_query.data?.is_verified;

  let can_associate_piv = false;
  if (account_query.data && privileges_query.data)
    can_associate_piv = canUserAssociatePiv(
      account_query.data.email,
      privileges_query.data.can_associate_piv_by_default
    );

  function handleSignOutClick(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    event.preventDefault();
    setIsMobileNavExpanded(false);
    handleSubMenu();
    // The logout mutation completely resets all queries, so onSuccess/onError do not fire when using logout.mutate().
    // Use the async method to capture success/error and respond with frontend actions.
    logout
      .mutateAsync()
      .then((data) => {
        const {login_service, logout_url} = data;
        if (login_service == 'login.gov' && logout_url) {
          navigate('/logout-confirmation', {state: {logout_url} as LogoutConfirmationState});
        } else {
          navigate('/', {state: {is_successful_logout: true} as MeetingsListAlertState});
        }
      })
      .catch(() => navigate('/', {state: {is_successful_logout: false} as MeetingsListAlertState}));
  }

  // NOTE TO DEVS: If you're in dev mode (not using IIS/proxy), it is expected for this to error
  const handleAssociatePivClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsMobileNavExpanded(false);
    handleSubMenu();
    pivMutation.mutate(undefined, {
      onSuccess: (data) => {
        scrollToTop();
        setPivAssociationState(data.association);
      },
      onError: () => {
        if (g_is_dev) {
          alert(
            "In dev mode? This logic won't work if you aren't using IIS with the correct SSL settings"
          );
        }
        setPivAssociationState('error');
      },
    });
  };

  return (
    <div className="nav__submenu_wrapper">
      {display_all && (
        <>
          <button
            className={`${styles.btn_wrapper} nav__hide_mobile ${isSubMenuOpen ? 'open' : ''}`}
            aria-expanded="false"
            aria-controls={name}
            onClick={handleSubMenu}
          >
            <div className={styles.icon_wrapper}>
              {account_query.isSuccess ? (
                <div className={styles.person_icon}>
                  {account_query.data.first_name?.charAt(0)}
                  {account_query.data.last_name?.charAt(0)}
                </div>
              ) : (
                <div className={styles.person_icon}> </div>
              )}
              {isSubMenuOpen ? (
                <Icon.ArrowDropUp className="text-white" role="presentation" />
              ) : (
                <Icon.ArrowDropDown className="text-white" role="presentation" />
              )}
            </div>
          </button>
          <ul
            id={name}
            className={`usa-nav__submenu nav__dropdown_menu ${styles.submenu} ${
              isSubMenuOpen ? 'open' : ''
            }`}
          >
            <li className="usa-nav__submenu-item nav__dropdown_menu_item">
              <Link
                to="my-account"
                onClick={() => {
                  setIsMobileNavExpanded(false);
                  handleSubMenu();
                }}
              >
                My account
              </Link>
            </li>
            {can_associate_piv && (
              <li className="usa-nav__submenu-item nav__dropdown_menu_item">
                <Link to="/" onClick={handleAssociatePivClick}>
                  Associate PIV card
                </Link>
              </li>
            )}
            <li className="usa-nav__submenu-item nav__dropdown_menu_item nav__hide_mobile">
              <Link to="/" onClick={handleSignOutClick}>
                Sign out
              </Link>
            </li>
          </ul>
        </>
      )}
      <button
        className={`usa-button usa-button--secondary width-full margin-top-3 desktop:margin-top-0${
          display_all ? ' nav__show_mobile' : ''
        }`}
        onClick={handleSignOutClick}
      >
        Sign out
      </button>
    </div>
  );
}

import {useMemo, useState} from 'react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  FilterFn,
  ColumnFilter,
  SortingFn,
} from '@tanstack/react-table';
import {Button, DatePicker, FormGroup, Label, Select, TextInput} from '@trussworks/react-uswds';
import StatusNameTag from '../../components/MeetingComponents/StatusNameTag/StatusNameTag';
import {Link, useLocation} from 'react-router-dom';
import {useQueryPrivileges} from '../../services/account-queries';
import {useQueryMeetings} from '../../services/meeting-queries';
import {MeetingsGetDataMeeting} from '../../../common/types/meetings-get-res';
import metadata_client_meeting from '../../lib/metadata-client-meeting.json';
import {DateTime} from 'luxon';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import TableUI from '../../components/TableUI/TableUI';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {
  capitalizeFirstLetter,
  eastern_tz,
  format_title,
  login_gov_failure,
  logout_err,
  logout_success,
  mtg_delete_success,
  mtg_list_err,
  self_acc_delete_success,
} from '../../lib/utils';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {MeetingsListAlertState} from '../../types/meeting-types';
import RegisterButton from '../../components/MeetingComponents/RegisterButton/RegisterButton';
import {useQueryRegistrations} from '../../services/registrant-queries';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

const tab_names = ['upcoming', 'past', 'all'] as const;

type TabName = (typeof tab_names)[number];

function getTabTitle(tab_name: TabName): string {
  return `${capitalizeFirstLetter(tab_name)} meetings`;
}

interface DateColumnFilter extends ColumnFilter {
  id: 'start_datetime';
  value: TabName;
}
type DateColumnFilterState = DateColumnFilter[];

type SelectedDateState = string | undefined;

const should_sort_desc: {[K in TabName]: boolean} = {
  upcoming: false,
  past: true,
  all: true,
};

export default function MeetingsList() {
  usePageTitle('Meetings');
  const [globalFilter, setGlobalFilter] = useState('');
  // If more columnFilters are added or the format of the value of the date filter is changed
  // then the getSortByDate call will need to be updated because it currently takes the value of columnFilters[0]
  const [columnFilters, setColumnFilters] = useState<DateColumnFilterState>([
    {id: 'start_datetime', value: 'upcoming'},
  ]);
  const [selectedDate, setSelectedDate] = useState<SelectedDateState>();
  const [sorting, setSorting] = useState<SortingState>([
    {id: 'start_datetime', desc: should_sort_desc['upcoming']},
  ]);
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const state = location.state as MeetingsListAlertState | null; // state passed from AccountBtn

  // Memoization of query results helps avoid unnecessary repaints, especially due to remounts. These memoized values
  // still change as soon as new data is available, but importantly, they do not result in repaints when the query
  // success status and data is unchanged between queries.
  const privileges_query = useQueryPrivileges({});
  const meetings_query = useQueryMeetings();
  const registrations_query = useQueryRegistrations();
  const meetings_data = useMemo(
    () => (meetings_query.isSuccess ? meetings_query.data : undefined),
    [meetings_query.isSuccess, meetings_query.data]
  );
  const [is_logged_in, is_verified, can_create_mtg] = useMemo(
    () =>
      privileges_query.isSuccess
        ? [true, privileges_query.data.is_verified, privileges_query.data.can_create_mtg]
        : [false, false, false],
    [privileges_query.isSuccess, privileges_query.data]
  );
  const registered_meetings = useMemo(
    () => (registrations_query.isSuccess ? registrations_query.data : []),
    [registrations_query.isSuccess, registrations_query.data]
  );

  const handleOnSelect = (idx: number) => {
    const tab_name = tab_names[idx];
    setColumnFilters([{id: 'start_datetime', value: tab_name}]);
    setSorting([{id: 'start_datetime', desc: should_sort_desc[tab_name]}]);
    setClickedIndex(null); // prevents persistent highlighting of Start Date column
    setTabIndex(idx);
  };

  function getFilterByTabAndDate(selectedDate: SelectedDateState) {
    const filterByTabAndDate: FilterFn<MeetingsGetDataMeeting> = (
      row,
      column_id,
      tab_name: DateColumnFilter['value']
    ) => {
      const mtg = row.original;

      if (selectedDate) {
        if (!mtg.start_datetime) return false;

        // Format the same as the date picker's values
        const formatted_start_date = DateTime.fromISO(mtg.start_datetime)
          .setZone(eastern_tz)
          .toFormat('MM/dd/yyyy');

        if (selectedDate != formatted_start_date) return false;
      }

      if (tab_name == 'all') return true;
      // Meetings without a start_datetime should be on the Upcoming (and All) tab regardless of end_datetime
      if (!mtg.start_datetime) return tab_name == 'upcoming';

      const current_date = DateTime.now().setZone(eastern_tz).startOf('day');
      const meeting_date = DateTime.fromISO(mtg.end_datetime || mtg.start_datetime)
        .setZone(eastern_tz)
        .startOf('day');
      return tab_name == 'upcoming' ? meeting_date >= current_date : meeting_date < current_date;
    };

    return filterByTabAndDate;
  }

  // Generating sortByDate to allow tab-specific sorting of null start dates.
  // The `tab_name` param is expected to come from columnFilter state.
  function getSortByDate(tab_name: TabName) {
    // Sorting docs: https://tanstack.com/table/v8/docs/api/features/sorting
    const sortByDate: SortingFn<MeetingsGetDataMeeting> = (row_a, row_b) => {
      const start_datetime_a = row_a.original.start_datetime;
      const start_datetime_b = row_b.original.start_datetime;
      if (!start_datetime_a && !start_datetime_b) return 0;
      if (!start_datetime_a) return tab_name == 'upcoming' ? -1 : 1;
      if (!start_datetime_b) return tab_name == 'upcoming' ? 1 : -1;

      const start_date_a = new Date(start_datetime_a);
      const start_date_b = new Date(start_datetime_b);
      return start_date_a < start_date_b ? -1 : start_date_a > start_date_b ? 1 : 0;
    };
    return sortByDate;
  }

  const columns = useMemo<ColumnDef<MeetingsGetDataMeeting>[]>(
    () => [
      {
        accessorFn: (row) => row.status.name,
        cell: (info) => <StatusNameTag name={info.getValue<string>()} />,
        header: metadata_client_meeting.status.title,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'start_datetime',
        cell: (info) => {
          const start_datetime = info.getValue<MeetingsGetDataMeeting['start_datetime']>();
          return (
            start_datetime &&
            DateTime.fromISO(start_datetime).setZone(eastern_tz).toLocaleString(DateTime.DATE_MED)
          );
        },
        header: metadata_client_meeting.start_datetime.title,
        // columnFilters[0].value will need to be updated if changes are made to the columnFilters
        sortingFn: getSortByDate(columnFilters[0].value),
        enableGlobalFilter: false,
        filterFn: getFilterByTabAndDate(selectedDate),
      },
      {
        accessorKey: 'title',
        cell: (info) => info.getValue(),
        header: metadata_client_meeting.title.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => {
          const format_strs = [];
          if (row.is_in_person) format_strs.push('In-person');
          if (row.is_virtual) format_strs.push('Virtual');
          return format_strs.join(', ');
        },
        header: format_title,
        enableGlobalFilter: false,
        sortingFn: 'text',
      },
      {
        id: 'registration_button',
        accessorFn: (row) => row,
        cell: (info) => {
          const data = info.getValue<MeetingsGetDataMeeting>();
          const is_registered = registered_meetings.includes(data.pk);
          return (
            <RegisterButton
              meeting_id={data.pk}
              meeting_reg_data={data}
              is_logged_in={is_logged_in}
              is_verified={is_verified}
              is_registered={is_registered}
              use_link_variant
            />
          );
        },
        header: 'Registration',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'pk',
        cell: (info) => (
          <Link to={`/meetings/${info.getValue<string>()}`} className="usa-link margin-right-0">
            Details
          </Link>
        ),
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    [columnFilters, selectedDate, is_logged_in, is_verified, registered_meetings]
  );

  const table = useReactTable({
    data: meetings_data ?? [],
    columns,
    state: {sorting, columnFilters, globalFilter},
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      {state && (
        <>
          {/* Prevent alert from persisting after refresh */}
          {window.history.replaceState({}, document.title)}
          {/* `null` guard is required since `is_successful_logout` can be false and/or undefined */}
          {state.is_successful_logout != null &&
            (state.is_successful_logout ? (
              <SuccessAlert>{logout_success}</SuccessAlert>
            ) : (
              <ErrorAlert>{logout_err}</ErrorAlert>
            ))}
          {state.is_mtg_deleted && <SuccessAlert>{mtg_delete_success}</SuccessAlert>}
          {state.is_acc_deleted && <SuccessAlert>{self_acc_delete_success}</SuccessAlert>}
          {state.is_login_gov_err && <ErrorAlert>{login_gov_failure}</ErrorAlert>}
        </>
      )}
      {can_create_mtg && (
        <Link to="/create-meeting" className="usa-button usa-button--secondary margin-bottom-4">
          Create new meeting
        </Link>
      )}
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word">
        {/* eslint-disable-next-line max-len */}
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            Search titles
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
        {/* eslint-disable-next-line max-len */}
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-top-neg-1">
          <Label htmlFor="date-search" className="text-italic mobile-lg:margin-right-4">
            Select start date &#40;mm/dd/yyyy&#41;
          </Label>
          <DatePicker
            id="date-search"
            name="date-search"
            onChange={(value) => {
              setSelectedDate(value);
              setColumnFilters([...columnFilters]); // Triggers the column filtering
            }}
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-6"
          />
        </FormGroup>
      </section>
      {meetings_query.isError ? (
        <ErrorAlert>{mtg_list_err}</ErrorAlert>
      ) : !meetings_data ? (
        <PageLoading message="Loading meetings" />
      ) : (
        <>
          <section className="margin-top-4">
            <Tabs onSelect={handleOnSelect} selectedIndex={tabIndex}>
              <TabList className="react-tabs__tab-list display-none mobile-lg:display-block">
                {tab_names.map((tab_name, idx) => (
                  <Tab key={idx}>{getTabTitle(tab_name)}</Tab>
                ))}
              </TabList>
              <Select
                className="display-block mobile-lg:display-none margin-bottom-2"
                id="mobile-tabs"
                name="mobile-tabs"
                onChange={(event) => handleOnSelect(event.target.selectedIndex)}
                value={tab_names[tabIndex]}
              >
                {tab_names.map((tab_name, idx) => (
                  <option key={idx} value={tab_name}>
                    {getTabTitle(tab_name)}
                  </option>
                ))}
              </Select>
              {tab_names.map((name, idx) => (
                <TabPanel key={idx} />
              ))}
            </Tabs>
          </section>
          {tab_names[tabIndex] == 'upcoming' && !table.getRowCount() ? (
            <p className="text-center margin-y-4">
              There are no upcoming meetings. Select{' '}
              <Button
                className="width-auto"
                unstyled
                type="button"
                onClick={() => handleOnSelect(tab_names.indexOf('past'))}
              >
                {getTabTitle('past').toLowerCase()}
              </Button>{' '}
              to see meetings that have already taken place.
            </p>
          ) : (
            <TableUI
              table={table}
              clickedIndex={clickedIndex}
              setClickedIndex={setClickedIndex}
              variant="meetings_list_table"
            />
          )}
        </>
      )}
      <div className="height-4">
        {/* This element adds space to account for the absolute positioned height-4 footer. */}
      </div>
      <footer className="pin-bottom pin-x bg-primary-dark height-4">
        <div className="grid-container">
          <div className="grid-row grid-gap-2 padding-y-1 font-body-3xs text-gray-5">
            <Link
              to="https://www.transportation.gov/vulnerability-disclosure-policy"
              className="text-gray-5 text-no-underline grid-col-auto"
            >
              Vulnerability Disclosure Policy
            </Link>
            {'|'}
            <Link
              to="https://www.transportation.gov/foia"
              className="text-gray-5 text-no-underline grid-col-auto"
            >
              FOIA
            </Link>
            {'|'}
            <Link
              to="https://www.transportation.gov/privacy"
              className="text-gray-5 text-no-underline grid-col-auto"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}

import {Checkbox} from '@trussworks/react-uswds';
import QuestionTooltip from '../../QuestionTooltip/QuestionTooltip';
import {HandleInputChange} from '../../../types/form-types';
import {Tooltip} from 'react-tooltip';

interface Props {
  id_name: string;
  is_checked: boolean;
  label: string;
  tooltip_text: string;
  is_disabled?: boolean;
  disabled_tooltip?: string;
  handleCheckbox: HandleInputChange;
}

export default function CheckboxWithTooltip({
  id_name,
  is_checked,
  label,
  tooltip_text,
  is_disabled,
  disabled_tooltip,
  handleCheckbox,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (is_disabled) return;
    handleCheckbox(event);
  };

  return (
    <div className="grid-row flex-align-center flex-no-wrap margin-top-1 mobile-lg:margin-right-2 width-full mobile-lg:width-auto">
      <div id={`${id_name}_container`}>
        <Checkbox
          id={id_name}
          name={id_name}
          checked={is_checked}
          aria-disabled={is_disabled}
          onChange={handleChange}
          className="margin-right-1 radius-md"
          label={label}
          tile
        />
      </div>
      <QuestionTooltip id={`${id_name}_tooltip`} className="flex-shrink-0">
        {tooltip_text}
      </QuestionTooltip>
      {is_disabled && !!disabled_tooltip && (
        <Tooltip anchorSelect={`#${id_name}_container`} place="bottom" className="z-top">
          {disabled_tooltip}
        </Tooltip>
      )}
    </div>
  );
}

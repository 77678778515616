import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import SuccessFalseError from '../lib/success-false-error';
import {EmailAuthReqBody} from '../../common/types/email-auth-req';
import {EmailAuthResBody} from '../../common/types/email-auth-res';
import {ErrorResBody} from '../../common/types/error-response';
import {TokenConfirmationGetPostResBody} from '../../common/types/token-confirmation-get-post-res';
import {LogoutResBody} from '../../common/types/logout-res';
import {PivAuthResBody} from '../../common/types/piv-auth-res';

export async function submitEmail(user_email_req: EmailAuthReqBody) {
  const res = await axios.post<EmailAuthResBody | ErrorResBody>('/api/auth', user_email_req);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationSubmitEmail() {
  return useMutation({mutationFn: submitEmail});
}

export async function checkToken(token: string) {
  const path = `/api/confirmation/${token}`;
  const res = await axios.get<TokenConfirmationGetPostResBody | ErrorResBody>(path);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryCheckToken(token: string, {is_enabled} = {is_enabled: true}) {
  return useQuery({
    queryKey: ['sl_token', token],
    queryFn: () => checkToken(token),
    enabled: is_enabled,
    retry: 1,
  });
}

export async function confirmToken(token: string) {
  const path = `/api/confirmation/${token}`;
  const res = await axios.post<TokenConfirmationGetPostResBody | ErrorResBody>(path);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationConfirmToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: confirmToken,
    onSuccess: async () => {
      await queryClient.resetQueries();
    },
  });
}

// When the app is hosted in IIS, don't expect error message or status codes to always be the same.
// IIS passes along its own error status codes for certain errors (those involving 4xx errors).
// Front end error handling should not be dependent on error status code for POST /api/piv or PIV auth routes
async function authenticatePiv() {
  const res = await axios.get<PivAuthResBody | ErrorResBody>('/piv');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationPivAuth() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: authenticatePiv,
    retry: false,
    onSuccess: async () => {
      await queryClient.resetQueries();
    },
  });
}

export async function logout() {
  const res = await axios.post<LogoutResBody | ErrorResBody>('/api/logout');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationLogout() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: logout,
    onSuccess: async () => {
      await queryClient.resetQueries();
    },
  });
}

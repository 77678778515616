import {GovBanner} from '@trussworks/react-uswds';
import {Link, useLocation, useNavigate, useRouteError} from 'react-router-dom';
import {generic_err_msg, isAxiosError, visit_homepage_title} from '../../lib/utils';
import PhmsaBanner from '../../layouts/RootLayout/PhmsaBanner/PhmsaBanner';
import SupportEmailLink from '../../components/SupportEmailLink/SupportEmailLink';
import PermissionsError from '../../lib/permissions-error';
import UnauthenticatedError from '../../lib/unauthenticated-error';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {app_name} from '../../../common/lib/shared-vars';

export default function ErrorBoundary() {
  usePageTitle('Error');
  const err = useRouteError();
  if (g_is_dev) console.error(err);
  let title = 'Error';
  let message = err instanceof Error ? err.message : 'Unexpected error';
  let contact_msg = 'Please reach out to';
  const err_code = isAxiosError(err) && err.response?.status;
  const location = useLocation();
  const navigate = useNavigate();

  if (err instanceof PermissionsError || err_code == 403) {
    title = 'Insufficient permissions';
    message = 'This account does not have sufficient permissions to access this page.';
    contact_msg = 'If this error is unexpected, please reach out to';
  } else if (err instanceof UnauthenticatedError || err_code == 401) {
    title = 'Please sign in';
    message = 'An account is required to access this page. Please sign in or create an account.';
    contact_msg = 'If this error is unexpected, please reach out to';
  }

  function handleHomeLinkClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (location.pathname == '/') {
      e.preventDefault();
      navigate('/', {replace: true});
    }
  }

  return (
    <>
      <GovBanner aria-label="Official government website" />
      <PhmsaBanner />
      <header className="usa-header usa-header--basic bg-primary">
        <div className="usa-nav-container desktop:height-10">
          <div className="usa-navbar display-flex flex-align-center height-full">
            <div className="usa-logo padding-y-2">
              <em className="usa-logo__text">
                <Link to="/" onClick={handleHomeLinkClick}>
                  {app_name}
                </Link>
              </em>
            </div>
          </div>
        </div>
      </header>
      <main className="grid-container padding-y-5">
        <p className="usa-intro">{generic_err_msg}</p>
        <h2>{title}</h2>
        <p>{message}</p>
        <p className="text-gray-50">
          {contact_msg} <SupportEmailLink />.
        </p>
        {err_code && (
          <p className="text-gray-50">
            <span className="text-bold">Error code:</span> {err_code}
          </p>
        )}
        <Link to="/" className="usa-button margin-top-2" onClick={handleHomeLinkClick}>
          {visit_homepage_title}
        </Link>
      </main>
    </>
  );
}
